import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { Project, Role } from '../../project.interface';
import { RoleService } from '../../../../../../shared/services/role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '../../../../../../shared/constants/constants';
import { ValidationService } from '../../../../../../shared/services/validation.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { DateTimeService } from '../../../../../../shared/services/date-time.service';
import { DynamicCredentialService } from '../../../../../../shared/services/dynamic-credential.service';
import { DynamicRoleCriteriaService } from '../../../../../../shared/services/dynamic-role-criteria.service';
import { EducationService } from '../../../../../../shared/services/education.service';
import { JobTypeService } from '../../../../../../shared/services/job-type.service';
import { SearchMarketService } from '../../../../../../shared/services/search-market.service';
import { UserService } from '../../../../../../shared/services/user.service';
import { TimezonePayload } from '../../../../../../shared/interfaces/date-time.interface';
import { JobType, DynamicCriteria, DynamicCredential } from '../../../../../../shared/interfaces/settings.interface';
import { SearchMarket } from '../../../people/people.interface';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { environment } from '../../../../../../../environments/environment';
import moment from 'moment';
import { HelperService } from '../../../../../../shared/services/helper.service';
import { TableRowExpandEvent, TableRowCollapseEvent, TableModule } from 'primeng/table';
import { PipesModule } from "../../../../../../shared/pipes/pipes.module";
import { FirmService } from '../../../../../../shared/services/firm.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ProjectNavigationService } from '../../../../../../shared/services/project-navigation.service';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService } from 'primeng/dynamicdialog';
import { RateBulkFreelancersComponent } from '../../project-listing/modals/rate-bulk-freelancers/rate-bulk-freelancers.component';
import { NotesService } from '../../../../../../shared/services/notes.service';
import { FirebaseMessagingService } from '../../../../../../shared/services/firebase-messaging.service';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../../../shared/services/company.service';

@Component({
    selector: 'app-role',
    standalone: true,
    templateUrl: './role.component.html',
    styleUrl: './role.component.scss',
    styles: [
        `
      :host ::ng-deep {
        [pDraggable] {
          cursor: move;
        }
      }
    `,
    ],
    imports: [
        DragDropModule,
        ChipModule,
        DialogModule,
        TabViewModule,
        TableModule,
        DropdownModule,
        CalendarModule,
        CheckboxModule,
        InputTextareaModule,
        FormsModule,
        RadioButtonModule,
        FloatLabelModule,
        InputTextModule,
        InputSwitchModule,
        MultiSelectModule,
        CommonModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        PipesModule,
        CheckboxModule,
        TooltipModule,
        RadioButtonModule
    ]
})
export class RoleComponent implements OnInit {
  @Output()
  changeTab: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  updateProjectState: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  sendOfferButtonFromRole: EventEmitter<number> = new EventEmitter<number>();
  @Input()
  recievedRoleId: any = null;

  showCreateForm = false;
  roleId: string | null = null;
  role: Role | null = null;
  projectId: string | null = null;
  project: Project | null = null;
  projectStatus: any;
  projectStartDate: any;
  projectType: any;
  isCreated = false;
  showProjectStep5Form: any;
  projectRoles: Role[] = [];
  isStartDateWithin7Days: any;
  occupiedReviewersCount = 0;
  reviewersCount = 0;
  timezones: Array<TimezonePayload> = [];
  searchMarkets: SearchMarket[] = [];
  jobTypes: JobType[] = [];
  dynamicRoleCriteriaList: DynamicCriteria[] = [];
  criteriaTypes: any = [];
  dynamicCredentialList: DynamicCredential[] = [];
  credentialTypes: Array<any> = [];
  firmId: string | null = null;
  selectedProducts: any[] | undefined;
  draggedProduct: any | undefined | null;
  showInhouseReviewer: boolean = false;
  degrees: {graduate: any[], underGraduate: any[]} = {
    graduate: [],
    underGraduate: []
  };
  roleDurations: any[] = [];
  weeklyExpectedHours: any[] = [];
  selectedDynamicCredential: any;
  selectedDynamicCriteria: any;
  workSchedule: any;

  btnlabel = 'Add Role';
  roleMinDate?: Date | null;
  roleMaxDate?: Date | null;
  staffByMinDate?: Date | null;
  staffByMaxDate?: Date | null;
  showSaturdayTime: boolean = false;
  showSundayTime: boolean = false;

  conflictQuestions: any[] = [];

  firmLocationsList: Array<any> = [];

  firmLocation: any = {
    firm: '',
    location: {
      city: '',
      officeName: '',
      state: '',
      street: '',
      suiteNo: '',
      zipCode: ''
    },
    __v: '',
    _id: ''
  };

  visiblePublishModal = false;
  disablePublishButton = false;
  submitted = false;
  visibleSaveModal = false;
  visibleDeleteModal = false;
  selectedDeleteRole: any;
  deleteRoleComment: string = '';
  restartDate = moment(new Date()).utc().toDate();
  restartTime = this.getDateTimeObjWithTime(9, 0) ;
  tempSchedule = {
    old: '',
    new: ''
  };
  roleStatus = constants.roleStatus;
  isRoleStartDateValid = true;
  isRoleStartDateWithin7DaysOfCurrentDate = true;
  offerSent = false;
  isGreaterThanTheCurrentDate = true;
  isStaffByDateLessThanRoleStartDate = true;
  isStaffByDateValid = true;
  isRoleStarted = false;
  isProjectStarted = false;
  activeExistingProjectRoles: Array<any> = [];
  title = environment.title;
  informationForm!: FormGroup;
  isInformationFormValid = false;
  schedulingForm!: FormGroup;
  isSchedulingFormValid = false;
  screeningForm!: FormGroup;
  activeIndex: number = 0;
  zipCodePattern: RegExp = /^\b\d{5}(-\d{4})?\b$/;
  unReadDiscussions: Array<any> = [];
  isUnreadMessagesExist = false;
  applyScheduling: boolean = true;
  applyConflictQuestions: boolean = true;
  roleDataScheduling!: any;
  roleDataConflictQuestions!: any;
  roleDetailsDisplay!:any;
  roleDetails!: Role;
  companies: any = [];
  expandedRows:{ [key: string]: boolean }  = {};
  education: any[] = [
    { name: 'N/A', key: 'N' },
    { name: 'Required', key: 'R' },
    { name: 'Specific', key: 'S' },
  ];
  credentialOptions: Array<any[]> = [];
  criteriaOptions: Array<any[]> = [];
  private destroy$ = new Subject<void>(); 
  constructor(
    public helperService: HelperService,
    private route: ActivatedRoute, 
    private validationService: ValidationService,
    private searchMarketService: SearchMarketService,
    private userService: UserService,
    private projectService: ProjectService,
    private jobTypeService: JobTypeService,
    private educationService: EducationService,
    private dynamicCredentialService: DynamicCredentialService,
    private dynamicRoleCriteriaService: DynamicRoleCriteriaService,
    private dateTimeService: DateTimeService,
    private roleService: RoleService,
    private firmService: FirmService,
    private toast: ToastService,
    private fb: FormBuilder,
    private router: Router,
    private projectNavigation: ProjectNavigationService,
    public dialogService: DialogService,
    private toastService: ToastService,
    private notesService: NotesService,
    private firebaseMessagingService: FirebaseMessagingService,
    private companyService: CompanyService,
  ){
    this.projectId = this.route.snapshot.paramMap.get('projectId');
   }

  async ngOnInit() {
    this.initialization();
    this.getUnreadMessageCount();
    this.companies = await this.companyService.getCompanies();
  }

  initialization(): void{
    this.firmId = this.userService.getUserFirmId();
    this.staffByMinDate = moment().startOf('day').toDate();
    this.staffByMaxDate = moment().endOf('day').toDate();
    this.roleMinDate = moment(new Date()).utc().toDate();
    this.roleMaxDate = moment(new Date()).utc().add(constants.daysDiffrenceBetweenDate, 'days').toDate();

    this.initForm();

    if(this.projectId){
      this.getProjectDetails();
    }
    this.loadTimezone();
    this.getJobTypes();
    this.getRoleDurations();
    this.getRoleWeeklyExpectedHours();
    this.getDegrees();
    this.getFirmSearchMarkets();
    this.getRoleCriteriaForFirm();
    this.getRoleCredentialForFirm();
    this.setDefaultTimezone();
    this.credentialOptions = []
    
    if(this.roleId){
      this.getRole();
    }
    else{
      this.initValues();
      this.getFirmLocationById();
      
    }

    
    this.onChangeFormValue();

    if(this.recievedRoleId){
      this.onEdit(this.recievedRoleId);
    }
  }

  setDefaultDurationAndWorkHourOnActiveRole(): void {
    if(this.activeExistingProjectRoles.length){
      const selectedDuration = this.roleDurations.find(duration => this.roleDataScheduling.workSchedule?.duration === duration.label);
      const selectedWorkHour = this.weeklyExpectedHours.find(hour => hour.label === this.roleDataScheduling.workSchedule?.workHours)
      const selectedTimezone = this.timezones.find(timezone => timezone.id === this.roleDataScheduling?.timezone);
      this.schedulingForm.controls['duration'].setValue(selectedDuration);
      this.schedulingForm.controls['workHours'].setValue(selectedWorkHour);
      this.schedulingForm.controls['timezone'].setValue(selectedTimezone);
    }
  }

  disableDateAndTime(): void{
    if(this.roleId && this.isProjectStarted && this.isRoleStarted) {
        this.schedulingForm.get('startDate')?.disable();
        this.schedulingForm.get('startTime')?.disable();
        this.schedulingForm.get('timezone')?.disable();
        this.schedulingForm.get('displayStaffBy')?.disable();
    }
    
  }

  setDefaultTimezone(): void{
    this.schedulingForm.controls['timezone'].setValue(this.timezones.length ? this.timezones[0] : '');
  }

  onChangeFormValue(): void{
    this.schedulingForm.get('weekdayStartTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('start', 'weekdayStartTime', 'weekDays');
      }
    });

    this.schedulingForm.get('weekdayEndTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('end', 'weekdayEndTime', 'weekDays');
      }
    });

    this.schedulingForm.get('saturdayStartTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('start', 'saturdayStartTime', 'saturday');
      }
    });

    this.schedulingForm.get('saturdayEndTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('end', 'saturdayEndTime', 'saturday');
      }
    });

    this.schedulingForm.get('sundayStartTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('start', 'sundayStartTime', 'sunday');
      }
    });

    this.schedulingForm.get('sundayEndTime')?.valueChanges.subscribe({
      next: () => {
        this.onChangeWeekDayTime('end', 'sundayEndTime', 'sunday');
      }
    });

    this.schedulingForm.get('startDate')?.valueChanges.subscribe({
      next: () => {
        const startDate = this.getFormValue(this.schedulingForm, 'startDate');
        if(!startDate){
          return;
        }
        this.validateCreateRoleStartDate(startDate, this.offerSent, this.isProjectStarted);
        
        this.staffByMaxDate = moment(startDate, "MM/DD/YYYY").endOf('day').utc().toDate();
      }
    });

    this.schedulingForm.get('displayStaffBy')?.valueChanges.subscribe({
      next: () => {
        const startDate = this.getFormValue(this.schedulingForm, 'startDate');
        if(!startDate){
          return;
        }
        this.roleStaffByDateChange(startDate, this.offerSent, this.isProjectStarted);
        
        this.staffByMaxDate = moment(startDate, "MM/DD/YYYY").endOf('day').utc().toDate();
      }
    });

    this.informationForm.get('searchMarket')?.valueChanges.subscribe({
      next: async () => {
        const payload = await this.getReviewerCountPayload();
        this.roleService
          .getReviewersCount(this.projectId!, payload)
          .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
        if(this.showInhouseReviewer){
          this.onShowInhouseReviewerSwitch()
        }
      }
    });

    this.informationForm.get('underGraduate')?.valueChanges.subscribe({
      next: async () => {
        const payload = await this.getReviewerCountPayload();
        this.roleService
          .getReviewersCount(this.projectId!, payload)
          .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
        if(this.showInhouseReviewer){
          this.onShowInhouseReviewerSwitch()
        }
      }
    });

    this.informationForm.get('graduate')?.valueChanges.subscribe({
      next: async () => {
        const payload = await this.getReviewerCountPayload();
        this.roleService
          .getReviewersCount(this.projectId!, payload)
          .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
        if(this.showInhouseReviewer){
          this.onShowInhouseReviewerSwitch()
        }
      }
    });

    this.informationForm.get('immigrationStatus')?.valueChanges.subscribe({
      next: async () => {
        const payload = await this.getReviewerCountPayload();
        this.roleService
          .getReviewersCount(this.projectId!,payload)
          .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
        if(this.showInhouseReviewer){
          this.onShowInhouseReviewerSwitch()
        }
      }
    });

    this.informationForm.get('selectedunderGradEducation')?.valueChanges.subscribe({
      next: () => {
        const education = this.getFormValue(this.informationForm, 'selectedunderGradEducation');
        
        if(education.key === 'R') {
          this.setFormValue(this.informationForm, 'underGraduateAny', true);
          this.setFormValue(this.informationForm, 'underGraduate', []);
          this.informationForm.get('educationNotRequired.underGrad')?.setValue(false);
          this.informationForm.get('underGraduate')?.clearValidators();
        }else if(education.key === 'N'){
          this.setFormValue(this.informationForm, 'underGraduateAny', false);
          this.setFormValue(this.informationForm, 'underGraduate', []);
          this.informationForm.get('educationNotRequired.underGrad')?.setValue(true);
          this.informationForm.get('underGraduate')?.clearValidators();
        }else {
          this.setFormValue(this.informationForm, 'underGraduateAny', false);
          this.informationForm.get('educationNotRequired.underGrad')?.setValue(false);
          this.informationForm.get('underGraduate')?.setValidators(Validators.required);
        }
        this.informationForm.get('underGraduate')?.updateValueAndValidity({emitEvent: false});
      }
    })

    this.informationForm.get('selectedGraduateEducation')?.valueChanges.subscribe({
      next: () => {
        const education = this.getFormValue(this.informationForm, 'selectedGraduateEducation');
        
        if(education.key === 'R') {
          this.setFormValue(this.informationForm, 'graduateAny', true);
          this.setFormValue(this.informationForm, 'graduate', []);
          this.informationForm.get('educationNotRequired.grad')?.setValue(false);
          this.informationForm.get('graduate')?.clearValidators();
        }else if(education.key === 'N'){
          this.setFormValue(this.informationForm, 'graduateAny', false);
          this.setFormValue(this.informationForm, 'graduate', []);
          this.informationForm.get('educationNotRequired.grad')?.setValue(true);
          this.informationForm.get('graduate')?.clearValidators();
        }else {
          this.setFormValue(this.informationForm, 'graduateAny', false);
          this.informationForm.get('educationNotRequired.grad')?.setValue(false)
          this.informationForm.get('graduate')?.setValidators(Validators.required);
        }
        this.informationForm.get('graduate')?.updateValueAndValidity({emitEvent: false});

      }
    })
  }

  setupFormListeners(formArray: any, typeControlName: string, anyControlName: string) {
    formArray.controls.forEach((control: any, index: number) => {
        control.get(typeControlName).valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            if (value && value.length > 0) {
                control.get(anyControlName).setValue(false, { emitEvent: false });
              }
              this.updateTypesValidation(control, typeControlName, anyControlName);
            }
          );
        
        control.get(anyControlName).valueChanges.pipe(takeUntil(this.destroy$)).subscribe((checked: any) => {
            if (checked) {
                control.get(typeControlName).setValue([], { emitEvent: false });
                control.get(typeControlName).disable({ emitEvent: false });
            } else {
                control.get(typeControlName).enable({ emitEvent: false });
            }
            this.updateTypesValidation(control, typeControlName, anyControlName);
        });
          
        if (control.get(anyControlName).value) {
            control.get(typeControlName).setValue([], { emitEvent: false });
            control.get(typeControlName).disable({ emitEvent: false });
        }
    });
}

  async updateTypesValidation(control: any, typeControlName: string, anyControlName: string) {
      const anyControlValue = control.get(anyControlName).value;
      const typesControl = control.get(typeControlName);

      if (anyControlValue) {
          typesControl.clearValidators();
          typesControl.disable({ emitEvent: false });
      } else {
          typesControl.setValidators([Validators.required]);
          typesControl.enable({ emitEvent: false });
      }

      typesControl.updateValueAndValidity({ emitEvent: false });
      const payload = await this.getReviewerCountPayload();
      this.roleService
        .getReviewersCount(this.projectId!, payload)
        .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
  }


  validateCreateRoleStartDate(roleStartdate: any, offerSent: any, isProjectStarted: any): void{
    if (roleStartdate) {
      const displayStaffBy = this.getFormValue(this.schedulingForm, 'displayStaffBy');
      this.isRoleStartDateWithin7DaysOfCurrentDate = true;
      this.isRoleStartDateValid = this.validationService.isValidDate(moment(roleStartdate).format('MM/DD/YYYY'));
      if (this.isRoleStartDateValid) {
          this.roleStaffByDateChange(displayStaffBy, offerSent, isProjectStarted);
      }
    }
  }

  roleStaffByDateChange(staffByDate: any, offerSent: any, isProjectStarted: any) {
    const startDate = moment(this.getFormValue(this.schedulingForm, 'startDate')).format('MM/DD/YYYY');
    const formattedStaffDate = moment(staffByDate).format('MM/DD/YYYY');
    if(!this.roleId){
        if (staffByDate) {
            this.isGreaterThanTheCurrentDate = false;
            this.isStaffByDateLessThanRoleStartDate = true;
            this.isStaffByDateValid = this.validationService.isValidDate(formattedStaffDate);
            if (this.isStaffByDateValid) {

                this.isGreaterThanTheCurrentDate = this.validationService.isGreaterThanCurrentDate(formattedStaffDate);
                this.isStaffByDateLessThanRoleStartDate = this.validationService.isLessThenEqualTo(staffByDate, startDate);
            }
        }
    }
    else{
      if (!(isProjectStarted && this.isRoleStarted)) {
          if (staffByDate) {

              this.isStaffByDateValid = this.validationService.isValidDate(formattedStaffDate);
              if (this.isStaffByDateValid) {

                  this.isGreaterThanTheCurrentDate = this.validationService.isGreaterThanCurrentDate(formattedStaffDate);
                  this.isStaffByDateLessThanRoleStartDate = this.validationService.isLessThenEqualTo(formattedStaffDate, startDate);
              }
          }
      }
    }
  }

  createCredentialControl(values?: any) {
    return this.fb.group({
      credential: values?.credential || this.fb.control(null),
      credentialAny: values?.credentialAny || this.fb.control(true),
      types: values?.types || this.fb.control([])
    });
  }

  addCredential(values?: any) {
    const formControl = this.informationForm.get('dynamicCredential') as FormArray;
    formControl.push(this.createCredentialControl(values));
    this.setupFormListeners(this.dynamicCredentials, 'types', 'credentialAny');
  }

  async removeCredential(index: number) {
    const formControl = this.informationForm.get('dynamicCredential') as FormArray;
    formControl.removeAt(index);
    this.credentialOptions.splice(index,1);
    const payload = await this.getReviewerCountPayload();
    this.roleService
      .getReviewersCount(this.projectId!, payload)
      .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
    if(this.showInhouseReviewer){
      this.onShowInhouseReviewerSwitch();
    }
  }

  get dynamicCredentials(): FormArray {
    return this.informationForm.get('dynamicCredential') as FormArray;
  }

  createCriteriaControl(values?: any) {
    return this.fb.group({
      criteria: values?.criteria || this.fb.control(null),
      criteriaAny: values?.criteriaAny || this.fb.control(true),
      types: values?.types || this.fb.control([])
    });
  }

  addCriteria(values?: any) {
    const formControl = this.informationForm.get('dynamicRoleCriteria') as FormArray;
    formControl.push(this.createCriteriaControl(values));
    this.setupFormListeners(this.dynamicCriterias, 'types', 'criteriaAny');
  }

  async removeCriteria(index: number) {
    const formControl = this.informationForm.get('dynamicRoleCriteria') as FormArray;
    formControl.removeAt(index);
    this.criteriaOptions.splice(index, 1);
    const payload = await this.getReviewerCountPayload();
    this.roleService
      .getReviewersCount(this.projectId!, payload)
      .subscribe((res: any) => (this.reviewersCount = res.reviewersCount));
    if(this.showInhouseReviewer){
      this.onShowInhouseReviewerSwitch();
    }
  }

  get dynamicCriterias(): FormArray {
    return this.informationForm.get('dynamicRoleCriteria') as FormArray;
  }
  
  initInformationForm(): void{
    this.informationForm = this.fb.group({
      roleType: ['', [Validators.required]],
      jobType: ['', [Validators.required]],
      searchMarket: ['', [Validators.required]],
      underGraduate: [[]],
      graduate: [[]],
      dynamicCredential: this.fb.array([]) ,
      dynamicCredentialTypes: [[]],
      dynamicRoleCriteria: this.fb.array([]),
      dynamicRoleCriteriaTypes: [[]],
      immigrationStatus: ['any'],
      isRemote: [true],
      reviewCenterLocation: this.fb.group({
          pointOfContact: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+[a-zA-Z0-9\.,#\-\s/]*$/)]],
          email: ['', [ Validators.pattern(constants.emailRegex)]],
          phoneNumber: ['', [ Validators.pattern(/^[0-9]{10}$/)]],
          instructions: ''
    },  { validators: this.atLeastOneValidator }),
      underGraduateAny: [false],
      graduateAny: [false],
      criteriaAny: [true],
      credentialAny: [true],
      selectedGraduateEducation: [this.education[0], Validators.required],
      selectedunderGradEducation: [this.education[0], Validators.required],
      educationNotRequired: this.fb.group({
          underGrad: [true],
          grad: [true]
      }),
    });
  }

  initSchedulingForm(): void {
    const displayStaffBy = moment().utc().toDate();
    this.schedulingForm = this.fb.group({
      startDate: [moment(new Date()).utc().toDate(), [Validators.required]],
      startTime: [this.getDateTimeObjWithTime(9, 0) ],
      timezone: [],
      displayStaffBy: [displayStaffBy, [Validators.required]],
      duration: [null, [Validators.required]],
      workHours: [null],
      workSchedule: this.fb.group({
        duration: '',
        schedule: '',
        workHours: ''
      }),
      weekDaysWillWork: [true],
      saturdayWillWork: [false],
      sundayWillWork: [false],
      weekdayStartTime: [this.getDateTimeObjWithTime(9, 0)],
      weekdayEndTime: [this.getDateTimeObjWithTime(18, 0)],
      saturdayStartTime: [this.getDateTimeObjWithTime(9, 0)],
      saturdayEndTime: [this.getDateTimeObjWithTime(18, 0)],
      sundayStartTime: [this.getDateTimeObjWithTime(9, 0)],
      sundayEndTime: [this.getDateTimeObjWithTime(18, 0)],
    })
  }

  initScreeningForm(): void{
    this.screeningForm = this.fb.group({
      scanForConflicts: [{
        fullCompanyNames: false,
        specificQuestions: false
      }],
      hasConflict: [false],
      conflictQuestions: [[]],
      question: [''],
    });
  }

  atLeastOneValidator(group: AbstractControl): ValidationErrors | null {
    const email = group.get('email')?.value;
    const phoneNumber = group.get('phoneNumber')?.value;
    if (email || phoneNumber) {
      return null;
    }
    return { atLeastOneRequired: true };
  }

  initForm(){
    if(this.informationForm && this.schedulingForm && this.screeningForm){
      return;
    }
    this.initInformationForm();
    this.initSchedulingForm();
    this.initScreeningForm();
  }

  get infoForm(): { [key: string]: AbstractControl } {
    return this.informationForm.controls;
  }

  get scheduleForm(): { [key: string]: AbstractControl } {
    return this.schedulingForm.controls;
  }

  onSundayCheck(e: any){
    this.showSundayTime = !this.showSundayTime;
  }

  onSaturdayCheck(e: any){
    this.showSaturdayTime = !this.showSaturdayTime;
  }

  initValues(){

    this.workSchedule = {
      startAt: '',
      schedule: {
        weekDays: {
          time: {
            start: '',
            end: ''
          },
          willWork: true
        },
        saturday: {
          time: {
            start: '',
            end: ''
          },
          willWork: false
        },
        sunday: {
          time: {
            start: '',
            end: ''
          },
          willWork: false
        },
      },
      duration: '',
      workHours: ''
    };
  }

  addQuestion() {
    const question = this.screeningForm.controls['question'].getRawValue();
    if (!question) {
        return;
    }

    this.conflictQuestions.push({ title: question, answer: false });
    this.screeningForm.controls['question'].setValue('');
  }

  removeQuestion(index: number) {
    this.conflictQuestions.splice(index, 1);
  }

  getProjectDetails() {
    this.projectService.getProjectById(this.projectId!).subscribe((response: Project) => {
      this.project = response;
      this.projectStatus = response.status;
      this.projectType = response.projectType;
      this.isProjectStarted = this.projectStatus === constants.projectStatus.started;
      this.projectStartDate = response.workSchedule.startAt;
      if (this.project.workSchedule.startAt) {
        this.setTempSchedule(this.tempSchedule, 'old', this.project.workSchedule.schedule);
      }
      if (this.project?.status === constants.projectStatus.created) {
        this.isCreated = true;
      }
      this.getProjectRoles();
    });
  }

  getProjectRoles() {
    this.roleService.getProjectRoles(this.projectId!).subscribe((resp: Role[]) => {
      this.showProjectStep5Form = true;
      this.projectRoles = resp;
      if(this.projectRoles && this.projectRoles.length){
        const startDate = this.projectRoles[0].startAt;
        this.isStartDateWithin7Days = this.validationService.isWithinSevenDaysOfCurrentDate_inputDateObj(startDate);
        if(this.isCreated){
          this.publish('');
        }
      }
      
      let params = { roles: this.projectRoles.map((role: any) => role._id) };
  
      if (params.roles.length > 0) {
        this.getReviewersCounts(params);
      }
    });
  }

  getReviewersCounts(params: any) {
    this.roleService.getReviewersCounts(this.projectId!, params)
    .subscribe((response: any) => {
      this.projectRoles.forEach((r: Role) => {
        const roleReviewerCount = response.find(function (e: any) { return e._id.toString() === r.id.toString(); });
        r.reviewersCount = roleReviewerCount ? roleReviewerCount.reviewersCount : 0;
      });
    });
  }

  async getFirmLocationById(): Promise<void>{
    this.firmLocationsList = await this.firmService.getFirmLocationById(this.firmId || '');
    const firmLocation = this.firmLocationsList.find(firmLoc => firmLoc._id === this.role?.reviewCenterLocation?.reviewLocation) || (this.firmLocationsList.length ? this.firmLocationsList[0] : null);
    if(firmLocation){
      this.firmLocation = firmLocation;
    }
  }

  getRole() {
    if (this.roleId) {
      this.roleService.getProjectRole(this.projectId!, this.roleId).subscribe({
        next: async (response: any) => {
          this.role = response;
          this.isRoleStarted = this.role?.isStarted || false;
          await this.getFirmLocationById();
          let credentialTemplate:any = [];
          if(this.role?.credentialTemplate.length){
            this.role.credentialTemplate.map((d:any)=>{
              const credential = this.dynamicCredentialList.find(item => item._id === d._id);
              if(credential){
                credentialTemplate.push(credential);
              }
            })
          }
          let criteriaTemplate:any = [];
          if(this.role?.criteriaTemplate.length){
            this.role.criteriaTemplate.map((d:any)=>{
              const criteria = this.dynamicRoleCriteriaList.find(item => item._id === d._id);

              if(criteria){
                criteriaTemplate.push(criteria);
              }
            })
          }
          const jobTypes = this.role?.jobType;

          const selectedJobTypes = this.jobTypes.filter((jType: any) => {
            return jobTypes?.some((item2: any) => (item2 === jType.name ))
          });

          const selectedSearchMarket = this.searchMarkets.filter((market: any) => {
            return this.role?.searchMarkets.some((m: any) => (m.name === market.name))
          })
          const startDate = this.role?.startAt;
          
          
          
          const selectedTimezone = this.timezones.find(timezone => timezone.id === this.role?.timezone);
          const selectedDuration = this.roleDurations.find(duration => this.role?.workSchedule.duration === duration.label);
          const selectedWorkHour = this.weeklyExpectedHours.find(hour => hour.label === this.role?.workSchedule.workHours)


          this.informationForm.controls['roleType'].setValue(this.role?.roleType);
          this.informationForm.controls['jobType'].setValue(selectedJobTypes);
          this.informationForm.controls['searchMarket'].setValue(selectedSearchMarket);

          this.schedulingForm.controls['duration'].setValue(selectedDuration);
          this.schedulingForm.controls['workHours'].setValue(selectedWorkHour);

          const graduatedDegree = this.degrees.graduate.filter((graduate: any) => {
            return this.role?.degrees.some((degree: any) => degree.major === graduate.major && degree._id === graduate._id && degree.graduate )
          });

          const underGraduate = this.degrees.underGraduate.filter((graduate: any) => {
            return this.role?.degrees.some((degree: any) => degree.major === graduate.major && degree._id === graduate._id && !degree.graduate )
          });

          if(graduatedDegree.length){
            this.setFormValue(this.informationForm, 'selectedGraduateEducation', this.education[2]);
          } else if(this.role?.educationNotRequired?.grad) {
            this.setFormValue(this.informationForm, 'selectedGraduateEducation', this.education[0]);
          }else{
            this.setFormValue(this.informationForm, 'selectedGraduateEducation', this.education[1]);
          }

          if(underGraduate.length){
            this.setFormValue(this.informationForm, 'selectedunderGradEducation', this.education[2]);
          }else if(this.role?.educationNotRequired?.underGrad) {
            this.setFormValue(this.informationForm, 'selectedunderGradEducation', this.education[0]);
          }else{
            this.setFormValue(this.informationForm, 'selectedunderGradEducation', this.education[1]);
          }

          this.informationForm.controls['graduate'].setValue(graduatedDegree);
          this.informationForm.controls['underGraduate'].setValue(underGraduate);

          this.setCredentialTemplate(credentialTemplate, this.role?.credential);
          this.setCriteriaTemplate(criteriaTemplate, this.role?.criteria);
          this.roleMinDate = this.getRoleMinDate(this.isProjectStarted?  new Date(): this.projectStartDate);
          this.roleMaxDate = this.getRoleMaxDate(this.isProjectStarted?  new Date(): this.projectStartDate);

          this.schedulingForm.controls['startDate'].setValue(moment(startDate).toDate());
          this.schedulingForm.controls['startTime'].setValue(startDate ? this.helperService.utcDateObject(startDate) : '');
          this.schedulingForm.controls['displayStaffBy'].setValue(moment(this.role?.displayStaffBy || this.role?.staffBy).toDate() ||  new Date());
          this.schedulingForm.controls['timezone'].setValue(selectedTimezone);

          this.informationForm.controls['immigrationStatus'].setValue(this.role?.immigrationStatus || 'any');
          this.informationForm.controls['isRemote'].setValue(this.role?.reviewCenterLocation.isRemote || false);

          
          this.informationForm.controls['reviewCenterLocation'].setValue({
            pointOfContact: this.role?.reviewCenterLocation.pointOfContact || '',
            email: this.role?.reviewCenterLocation.email || '',
            phoneNumber: this.role?.reviewCenterLocation.phoneNumber || '',
            instructions: this.role?.reviewCenterLocation.instructions || ''
          });
          this.screeningForm.controls['hasConflict'].setValue(this.role?.hasConflict);

          this.setWorkSchedule(this.role);
          this.disableDateAndTime();

          this.conflictQuestions = response.conflictQuestions;
          this.workSchedule = response.workSchedule;
        }
      });  
    }
  }

  setWorkSchedule(role: Role | null): void{
    
    if(role?.workSchedule && role?.workSchedule.duration){
      if(role?.workSchedule && role?.workSchedule.schedule.weekDays && role?.workSchedule.schedule.weekDays.time){
        this.schedulingForm.controls['weekDaysWillWork'].setValue(!!(role?.workSchedule.schedule.weekDays.willWork));

        if(role?.workSchedule.schedule.weekDays.time.start){
          this.schedulingForm.controls['weekdayStartTime'].setValue(
            this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.weekDays.time.start)
          );
        }
        if(role?.workSchedule.schedule.weekDays.time.end){
            this.schedulingForm.controls['weekdayEndTime'].setValue(
              this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.weekDays.time.end)
          );
        }
      }else{
        this.schedulingForm.controls['weekDaysWillWork'].setValue(false);
      }

      if (role?.workSchedule && role?.workSchedule.schedule.saturday && role?.workSchedule.schedule.saturday.time) {
        this.schedulingForm.controls['saturdayWillWork'].setValue(!!(role?.workSchedule.schedule.saturday.willWork));
        if(role?.workSchedule.schedule.saturday.time.start){
            this.schedulingForm.controls['saturdayStartTime'].setValue(
              this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.saturday.time.start)
            )
        }
        if(role?.workSchedule.schedule.saturday.time.end){
            this.schedulingForm.controls['saturdayEndTime'].setValue(
              this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.saturday.time.end)
            );
        }
      }else{
        this.schedulingForm.controls['saturdayWillWork'].setValue(false);
      }

      if (role?.workSchedule && role?.workSchedule.schedule.sunday && role?.workSchedule.schedule.sunday.time) {
        this.schedulingForm.controls['sundayWillWork'].setValue(!!(role?.workSchedule.schedule.sunday.willWork));
        if(role?.workSchedule.schedule.sunday.time.start){
            this.schedulingForm.controls['sundayStartTime'].setValue(
              this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.sunday.time.start)
            );
        }
        if(role?.workSchedule.schedule.sunday.time.end){
            this.schedulingForm.controls['sundayEndTime'].setValue(
              this.setDisplayTimeFromWorkingHours(role?.workSchedule.schedule.sunday.time.end)
            );
        }
      }else{
        this.schedulingForm.controls['sundayWillWork'].setValue(false);
      }
    }
  }

  getJobTypes() {
    this.jobTypeService.getGlobalJobTypeListingByFirmId(this.firmId!).subscribe((response) => { 
      this.jobTypes = response;
    });
  }

  getFormValue(form: FormGroup, property: string): any {
    return form.controls[property].getRawValue();
  }

  getNestedInformationFormValue(parentProperty: string, property: string): any {
    const parentObject = this.informationForm.controls[parentProperty].getRawValue();
    return parentObject[property];
  }

  getRoleDurations() {
    this.roleDurations = this.helperService.getRoleDurations();
    
  }

  getRoleWeeklyExpectedHours() {
    this.weeklyExpectedHours = this.helperService.getRoleWeeklyExpectedHours();
  }

  loadTimezone() {
    this.timezones = this.dateTimeService.getTimeZoneList(environment.usSpecificTimezoneOnly);
    
  }

  getRoleCriteriaForFirm() {
    this.dynamicRoleCriteriaService.getFirmDynamicRoleCriteria(this.firmId!).subscribe((response: any) => {
      this.dynamicRoleCriteriaList = response.list;
    })
  }

  onChangeDynamicCriteriaList(event: any, index: number) {
    if (this.criteriaOptions[index]) {
      this.selectedDynamicCriteria = event.value;
      const controls = this.dynamicCriterias?.controls;
      if (controls && controls[index]){
        controls[index].get('types')?.setValue([], { emitEvent: false });
      }
      if (this.selectedDynamicCriteria) {
        const data =
          JSON.parse(this.selectedDynamicCriteria.config.criteria) ||
          {label:{values: []}};
        data.label?.values?.forEach((item:any) => item.criteriaId = this.selectedDynamicCriteria._id);
        this.criteriaOptions[index] = data.label.values;
      }else{
        this.criteriaOptions[index] = []
      }
    } else {
      this.selectedDynamicCriteria = event.value;
      if (this.selectedDynamicCriteria) {
        const data =
          JSON.parse(this.selectedDynamicCriteria.config.criteria) ||
          {label:{values: []}};
        data.label?.values?.forEach((item:any) => item.criteriaId = this.selectedDynamicCriteria._id);
        this.criteriaOptions.push(data.label.values);
      }
    }
  }

  getRoleCredentialForFirm() {
    this.dynamicCredentialService.getFirmDynamicCredential(this.firmId!).subscribe((response: any) => {
      this.dynamicCredentialList = response.list;
    })
  }

  onChangeDynamicCredentialList(event: any, index: number) {
      if (this.credentialOptions[index]) {
        this.selectedDynamicCredential = event.value;
        const controls = this.dynamicCredentials?.controls;
        if (controls && controls[index]){
          controls[index].get('types')?.setValue([], { emitEvent: false });
        }
        if (this.selectedDynamicCredential) {
          const data =
            JSON.parse(this.selectedDynamicCredential?.secondaryOptions?.options) ||
            {values: []};
          data.values?.forEach((item:any) => item.credId = this.selectedDynamicCredential._id);
          this.credentialOptions[index] = data.values;
        }else{
          this.credentialOptions[index] = []
        }
      } else {
        this.selectedDynamicCredential = event.value;
        if (this.selectedDynamicCredential) {
          const data =
            JSON.parse(this.selectedDynamicCredential?.secondaryOptions?.options) ||
            {values: []};
          data.values?.forEach((item:any) => item.credId = this.selectedDynamicCredential._id);
          this.credentialOptions.push(data.values);
        }
      }
    }

  setCredentialTemplate(credentialTemplate: any, credentials: any){
    if(credentialTemplate.length){
      this.removeCredential(0);
      this.credentialOptions = [];
      credentialTemplate.forEach((credential: any, i: number) => {
        const cred = this.dynamicCredentialList.find((item:any)=> item._id === credential._id);
        const data =
          JSON.parse(cred?.secondaryOptions?.options  ||
          '{"values": []}');
        data.values?.forEach((item:any) => item.credId = credential._id);
        this.credentialOptions.push(data.values);
        const types = this.credentialOptions[i]?.filter((option: any) => 
          credentials?.some((cred: any) => option.credId === cred.credId && option.val === cred.val)
        );
        const credAny = types.length? false: true;
        const values = {
          credential: [cred],
          credentialAny: [credAny],
          types: [types]
        };
        this.addCredential(values)
      })
    }
  }

  setCriteriaTemplate(criteriaTemplate: any, criterias: any){
    if(criteriaTemplate.length){
      this.removeCriteria(0);
      this.criteriaOptions = [];
      criteriaTemplate.forEach((criteria: any, i: number) => {
        const cred = this.dynamicRoleCriteriaList.find((item:any)=> item._id === criteria._id);
        const data =
          JSON.parse(cred?.config?.criteria  ||
          '{"label": {"values": []}}');
        data.label?.values?.forEach((item:any) => item.criteriaId = criteria._id);
        this.criteriaOptions.push(data.label.values);
        const types = this.criteriaOptions[i]?.filter((option: any) => 
          criterias[0]?.labels?.values.some((item: any) => 
              item.val === option.val && item.criteriaId === option.criteriaId
          )
        );
        const credAny = types.length? false: true;
        const values = {
          criteria: [cred],
          criteriaAny: [credAny],
          types: [types]
        };
        this.addCriteria(values)
      })
    }
  }

  getFirmSearchMarkets() {
    this.searchMarketService.getFirmSearchMarkets(this.firmId!).subscribe((response: SearchMarket[]) => {
      this.searchMarkets = response.filter((s: SearchMarket) => s.isActive === true);
    });
  }

  getDegrees() {
    this.educationService.getDegrees().subscribe({
      next: (response: any) => {
        let data = response;
        this.degrees = {
          graduate: [],
          underGraduate: []
        };
       
        data.forEach((degree: any) => { 
          degree.majors.forEach((major: any) => {
            if (degree.graduate) {
              this.degrees.graduate.push({
                'type': degree.type,
                'major': major,
                'typeMajor': degree.type + ' - ' + major,
                'graduate': degree.graduate,
                _id: degree._id
              });
            } else {
              this.degrees.underGraduate.push({
                'type': degree.type,
                'major': major,
                'typeMajor': degree.type + ' - ' + major,
                'graduate': degree.graduate,
                _id: degree._id
              });
            }
          });
        });
      }
    });
  }

  setDegrees(degrees: any) {
    const graduateDegrees: any[] = [];
    const underGraduateDegrees: any[] = [];
    degrees.forEach((degree: any) => {
      if (degree.graduate) {
        graduateDegrees.push({
          'type': degree.type,
          'major': degree.major,
          'typeMajor': degree.type + ' - ' + degree.major,
          'graduate': degree.graduate,
          _id: degree._id
        });
      } else {
        underGraduateDegrees.push({
          'type': degree.type,
          'major': degree.major,
          'typeMajor': degree.type + ' - ' + degree.major,
          'graduate': degree.graduate,
          _id: degree._id
        });
      }
    });
  }

  async onShowInhouseReviewerSwitch(){
    const payload = await this.getReviewerCountPayload();
    return this.roleService.getReviewersCount(this.projectId!, payload, constants.select.reviewerQueryType.occupied)
      .subscribe({
        next: (response: any) => { this.occupiedReviewersCount = response.reviewersCount; }
    });
  }

  async getReviewerCountPayload(): Promise<any>{
    const location = await this.saveReviewerLocation();
    const createPayload = this.getCreatePayload(location);
    const payload: any = {
      ...createPayload,
      foreignLanguageExperiences: [],
      reviewExperiences: [],
      softwareExperiences: [],
      contractExperiences: [],
      industryExperiences: [],
      certifications: [],
      trainings: [],
      cyberSkills: [],
      cyberExperiences: [],
      roleType: '',
      experiences: [],
      skills: [],
      jobType: []
    }

    if(this.roleId){
      const roleType = this.informationForm.controls['roleType'].getRawValue();
      payload._id = this.roleId;
      payload.roleType = roleType;
      payload.notifications = {
        schedule: this.isScheduleChanged(this.tempSchedule)
      }
    }
    return payload;
  }

  getRoleDateSet(projectStartDate: string, roleStartDate: string) {
    if (roleStartDate) {
      return moment(new Date(roleStartDate)).utc().format("MM/DD/YYYY");
    }
    else {
      if (this.helperService.checkIfDateIsLessThanCurrentDate(projectStartDate)) {
        return moment(new Date()).utc().format("MM/DD/YYYY");
      }
      else {
        return moment(new Date(projectStartDate)).utc().format("MM/DD/YYYY");
      }
    }
  }

  getRoleMinDate(projectStartDate: string) {
    if (this.helperService.checkIfDateIsLessThanCurrentDate(projectStartDate)) {
      return moment(new Date()).utc().toDate();
    }
    else {
      return moment(new Date(projectStartDate)).utc().toDate();
    }
  }

  getRoleMaxDate(projectStartDate: string) {
    if (this.helperService.checkIfDateIsLessThanCurrentDate(projectStartDate)) {
      return moment(new Date()).utc().add(constants.daysDiffrenceBetweenDate, 'days').toDate();
    }
    else {
      return moment(new Date(projectStartDate)).utc().add(constants.daysDiffrenceBetweenDate, 'days').toDate();
    }
  }
  
  dragStart(product: any) {
    this.draggedProduct = product;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.conflictQuestions!, event.previousIndex, event.currentIndex);
  }

  dragEnd() {
    this.draggedProduct = null;
  }

  findIndex(product: any) {
    let index = -1;
    for (let i = 0; i < (this.conflictQuestions as any[]).length; i++) {
      if (product.id === (this.conflictQuestions as any[])[i].id) {
        index = i;
        break;
      }
    }
    return index;
  }

  onChangeWeekDayTime(property: string, controlName: string, day: string): void{
    const time = moment(new Date(
      this.schedulingForm.controls[controlName].getRawValue()
    ), 'HH:mm');
    const workSchedule: any = this.schedulingForm.controls['workSchedule'].getRawValue();
    workSchedule.schedule = workSchedule.schedule ? {...workSchedule.schedule} : {};
    workSchedule.schedule[day] = workSchedule.schedule[day] ? {...workSchedule.schedule[day]} : {}

    workSchedule.schedule[day] = {
      ...workSchedule.schedule[day],
      time: workSchedule.schedule[day].time ? {...workSchedule.schedule[day].time} : { }
    }
    workSchedule.schedule[day].time[property] = {
      
      hour: Number(time.format('hh')),
      min: Number(time.format('mm')),
      meridiem: time.format('A').toLowerCase()
    }
    this.schedulingForm.controls['workSchedule'].setValue(workSchedule);
  }

  async saveReviewerLocation(): Promise<any>{
    if(this.getFormValue(this.informationForm, 'isRemote')){
      return;
    }
    return await this.firmService.createFirmReviewLocation(this.firmId || '', {location: {...this.firmLocation.location}});
  }

  isDynamicCredentialValidated(): boolean{
    if(!this.dynamicCredentials.length){
      return false;
    }
    return true;
  }

  isDynamicCriteriaValidated(): boolean{
    if(!this.dynamicCriterias.length){
      return false;
    }
    return true;
  }

  isReviewerValidated(): boolean {
    if(!this.getFormValue(this.informationForm, 'isRemote')){
      if(!this.firmLocation?.location?.officeName){
        return false;
      }

      if(!this.firmLocation?.location?.street){
        return false;
      }

      if(!this.firmLocation?.location?.city){
        return false;
      }

      if(!this.firmLocation?.location?.state){
        return false;
      }

      if(!(this.firmLocation?.location?.zipCode && this.zipCodePattern.test(this.firmLocation.location.zipCode))){
        return false;
      }
    }

    return true;
  }

  eitherPhoneNumberAndEmailNotEmpty(): boolean{
    if(!this.getNestedInformationFormValue('reviewCenterLocation', 'email') && !this.getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
      return false;
    }
    return true;
  }

  isDegreeValidated(): boolean {
    const underGraduate = this.getFormValue(this.informationForm, 'underGraduate');
    if(underGraduate && underGraduate.length > 5){
      this.toast.showError('Select underGraduate upto 5.');
      return false;
    }

    const graduate = this.getFormValue(this.informationForm, 'graduate');
    if(graduate && graduate.length > 5){
      this.toast.showError('Select graduate upto 5.');
      return false;
    }

    return true;
  }

  isValidatedAllDates(): boolean{
    return this.isGreaterThanTheCurrentDate && this.isStaffByDateLessThanRoleStartDate && this.isStaffByDateValid;
  }

  setDefaultWorkScheduleIfActiveRoleExists(): void {
    const workSchedule: any = {
      schedule: {

      }
    };
    const schedule = this.roleDataScheduling.workSchedule?.schedule;

    const weekDaysWillWork = schedule?.weekDays?.willWork || false;
    const saturdayWillWork = schedule?.saturday?.willWork || false;
    const sundayWillWork = schedule?.sunday?.willWork || false;
    this.schedulingForm.controls['weekDaysWillWork'].setValue(weekDaysWillWork);
    this.schedulingForm.controls['saturdayWillWork'].setValue(saturdayWillWork);
    this.schedulingForm.controls['sundayWillWork'].setValue(sundayWillWork);
    if(weekDaysWillWork){
      workSchedule.schedule.weekDays = {
        time: {
          start: {
            hour: Number(schedule?.weekDays?.time?.start?.hour || 9),
            min: Number(schedule?.weekDays?.time?.start?.min || 0),
            meridiem: schedule?.weekDays?.time?.start?.meridiem
          },
          end: {
            hour: Number(schedule?.weekDays?.time?.end?.hour || 9),
            min: Number(schedule?.weekDays?.time?.end?.min || 0),
            meridiem: schedule?.weekDays?.time?.end?.meridiem
          }
        },
        willWork: true
      }

      this.schedulingForm.controls['weekdayStartTime'].setValue(this.getDateTimeObjWithTime(schedule?.weekDays?.time?.start?.hour || 9, schedule?.weekDays?.time?.start?.min || 0))
      this.schedulingForm.controls['weekdayEndTime'].setValue(this.getDateTimeObjWithTime(schedule?.weekDays?.time?.end?.hour || 9, schedule?.weekDays?.time?.end?.min || 0))
    } else {
      workSchedule.schedule = {
        weekDays: {
          willWork: false
        }
      }
    }

    if(saturdayWillWork){
      workSchedule.schedule.saturday = {
        time: {
          start: {
            hour: Number(schedule?.saturday?.time?.start?.hour || 9),
            min: Number(schedule?.saturday?.time?.start?.min || 0),
            meridiem: schedule?.saturday?.time?.start?.meridiem
          },
          end: {
            hour: Number(schedule?.saturday?.time?.end?.hour || 9),
            min: Number(schedule?.saturday?.time?.end?.min || 0),
            meridiem: schedule?.saturday?.time?.end?.meridiem
          }
        },
        willWork: true
      }

      this.schedulingForm.controls['saturdayStartTime'].setValue(this.getDateTimeObjWithTime(schedule?.saturday?.time?.start?.hour || 9, schedule?.saturday?.time?.start?.min || 0));
      this.schedulingForm.controls['saturdayEndTime'].setValue(this.getDateTimeObjWithTime(schedule?.saturday?.time?.end?.hour || 9, schedule?.saturday?.time?.end?.min || 0));
    } else {
      workSchedule.schedule.saturday = {
        willWork: false
      }
    }

    if(sundayWillWork){
      workSchedule.schedule.sunday = {
        time: {
          start: {
            hour: Number(schedule?.sunday?.time?.start?.hour || 9),
            min: Number(schedule?.sunday?.time?.start?.min || 0),
            meridiem: schedule?.sunday?.time?.start?.meridiem
          },
          end: {
            hour: Number(schedule?.sunday?.time?.end?.hour || 9),
            min: Number(schedule?.sunday?.time?.end?.min || 0),
            meridiem: schedule?.sunday?.time?.end?.meridiem
          }
        },
        willWork: true
      }
      this.schedulingForm.controls['sundayStartTime'].setValue(this.getDateTimeObjWithTime(schedule?.sunday?.time?.start?.hour || 9, schedule?.sunday?.time?.start?.min || 0))
      this.schedulingForm.controls['sundayEndTime'].setValue(this.getDateTimeObjWithTime(schedule?.sunday?.time?.end?.hour || 9, schedule?.sunday?.time?.end?.min || 0))
    } else {
      workSchedule.schedule.sunday = {
        willWork: false
      }
    }

    return workSchedule;
  }

  getWorkSchedule(): any{
    const weekDayStartTime = moment(new Date(
      this.schedulingForm.controls['weekdayStartTime'].getRawValue()
    ), 'HH:mm');

    const weekdayEndTime = moment(new Date(
      this.schedulingForm.controls['weekdayEndTime'].getRawValue()
    ), 'HH:mm');

    const saturdayStartTime = moment(new Date(
      this.schedulingForm.controls['saturdayStartTime'].getRawValue()
    ), 'HH:mm');

    const saturdayEndTime = moment(new Date(
      this.schedulingForm.controls['saturdayEndTime'].getRawValue()
    ), 'HH:mm');

    const sundayStartTime = moment(new Date(
      this.schedulingForm.controls['sundayStartTime'].getRawValue()
    ), 'HH:mm');

    const sundayEndTime = moment(new Date(
      this.schedulingForm.controls['sundayEndTime'].getRawValue()
    ), 'HH:mm');

    const weekDaysWillWork = this.schedulingForm.controls['weekDaysWillWork'].getRawValue();
    const saturdayWillWork = this.schedulingForm.controls['saturdayWillWork'].getRawValue();
    const sundayWillWork = this.schedulingForm.controls['sundayWillWork'].getRawValue();
    const workSchedule = { ...this.schedulingForm.value.workSchedule };
    if(!workSchedule.schedule){
      workSchedule.schedule = {};
    }

    if(weekDaysWillWork){
      workSchedule.schedule.weekDays = {
        time: {
          start: {
            hour: Number(weekDayStartTime.format('hh')),
            min: Number(weekDayStartTime.format('mm')),
            meridiem: weekDayStartTime.format('A').toLowerCase()
          },
          end: {
            hour: Number(weekdayEndTime.format('hh')),
            min: Number(weekdayEndTime.format('mm')),
            meridiem: weekdayEndTime.format('A').toLowerCase()
          }
        },
        willWork: true
      }
    } else {
      workSchedule.schedule = {
        weekDays: {
          willWork: false
        }
      }
    }

    if(saturdayWillWork){
      workSchedule.schedule.saturday = {
        time: {
          start: {
            hour: Number(saturdayStartTime.format('hh')),
            min: Number(saturdayStartTime.format('mm')),
            meridiem: saturdayStartTime.format('A').toLowerCase()
          },
          end: {
            hour: Number(saturdayEndTime.format('hh')),
            min: Number(saturdayEndTime.format('mm')),
            meridiem: saturdayEndTime.format('A').toLowerCase()
          }
        },
        willWork: true
      }
    } else {
      workSchedule.schedule.saturday = {
        willWork: false
      }
    }

    if(sundayWillWork){
      workSchedule.schedule.sunday = {
        time: {
          start: {
            hour: Number(sundayStartTime.format('hh')),
            min: Number(sundayStartTime.format('mm')),
            meridiem: sundayStartTime.format('A').toLowerCase()
          },
          end: {
            hour: Number(sundayEndTime.format('hh')),
            min: Number(sundayEndTime.format('mm')),
            meridiem: sundayEndTime.format('A').toLowerCase()
          }
        },
        willWork: true
      }
    } else {
      workSchedule.schedule.sunday = {
        willWork: false
      }
    }

    return workSchedule;
  }

  getEditPayload(location: any): any{

    const credentialsArray = this.dynamicCredentials.controls;
    const credentialTemplate = credentialsArray.map((control: any) => control?.get('credential').value);
    const dynamicCredentialTypes = credentialsArray.map((control:any) => control?.get('types').value);
    const credentialAnyArray = credentialsArray.map((control:any) => control?.get('credentialAny').value).every(element => element === true);

    const criteriaArray = this.dynamicCriterias.controls;
    const criteriaTemplate = criteriaArray.map((control: any) => control?.get('criteria').value);
    const dynamicRoleCriteriaTypes = criteriaArray.map((control:any) => control?.get('types').value);
    const criteriaAnyArray = criteriaArray.map((control:any) => control?.get('criteriaAny').value).every(element => element === true);

    const graduate = this.informationForm.controls['graduate'].getRawValue();
    const underGraduate = this.informationForm.controls['underGraduate'].getRawValue();

    const immigrationStatus = this.informationForm.controls['immigrationStatus'].getRawValue();
    const reviewCenterLocation = this.informationForm.controls['reviewCenterLocation'].getRawValue();
    const jobType = this.informationForm.controls['jobType'].getRawValue();
    const searchMarket = this.informationForm.controls['searchMarket'].getRawValue();
    const startDate = moment(this.schedulingForm.controls['startDate'].getRawValue()).format("MM/DD/YYYY");
    const roleType = this.informationForm.controls['roleType'].getRawValue();
    const selectedDuration = this.schedulingForm.controls['duration'].getRawValue();
    const selectedWorkHours = this.schedulingForm.controls['workHours'].getRawValue() || '';

    const startTime = this.schedulingForm.controls['startTime'].getRawValue();

    const startAt = moment(startDate + ' ' + moment(startTime).format('LTS')).format('YYYY-MM-DDTHH:mm:ss') + '.000Z';

    const workSchedule = this.getWorkSchedule();

    const displayStaffBy = moment(this.getFormValue(this.schedulingForm, 'displayStaffBy')).format('MM/DD/YYYY');
    const staffByTime = new Date();
    staffByTime.setHours(0, 0, 0);

    const tempStaffBy = moment(new Date(displayStaffBy));
    const tempStaffByTime = moment(staffByTime);

    const staffBy = moment.utc([
      tempStaffBy.year(), 
      tempStaffBy.month(), 
      tempStaffBy.date(), 
      tempStaffByTime.hour(), 
      tempStaffByTime.minute()
    ]).format();

    const hasConflict =  this.screeningForm.controls['hasConflict'].getRawValue();
    const isRemote = this.informationForm.controls['isRemote'].getRawValue();
    const timezone = this.schedulingForm.controls['timezone'].getRawValue();
    
    const scanForConflict = {
      ...this.role?.scanForConflicts,
      specificQuestions: hasConflict? true : false,
    };

    const payload: any = {
      conflictQuestions: this.conflictQuestions.slice(),
      staffByTime,
      displayStaffBy,
      staffBy,
      timezone:  timezone.id || '',
      startAt,
      barMembership: this.role?.barMembership,
      continueSendingOffers: this.role?.continueSendingOffers || false,
      degrees: this.informationForm.get('educationNotRequired.underGrad')?.value && this.informationForm.get('educationNotRequired.grad')?.value ? [] : graduate.concat(underGraduate),
      undergraduateDegrees: this.informationForm.get('educationNotRequired.underGrad')?.value ? [] : underGraduate,
      graduateDegrees: this.informationForm.get('educationNotRequired.grad')?.value ? [] : graduate,
      experiences: this.role?.experiences,
      hasConflict,
      id: this.role?.id,
      immigrationStatus,
      isDeleted: this.role?.isDeleted,
      isStarted: this.role?.isStarted,
      jobType: jobType.map((jt: any) => jt.name),
      lateOfferAcceptedReviewers: this.role?.lateOfferAcceptedReviewers,
      offerCancelledDates: this.role?.offerCancelledDates,
      offerSent: this.role?.offerSent,
      offersSentCount: this.role?.offersSentCount,
      overTime: this.role?.overTime,
      payType: this.role?.payType,
      project: this.projectId || '',
      reviewCenterLocation: {
        ...this.role?.reviewCenterLocation, 
        reviewLocation: location?._id || this.role?.reviewCenterLocation.reviewLocation,
        isRemote: isRemote,
        email: reviewCenterLocation.email || '',
        instructions: reviewCenterLocation.instructions || '',
        pointOfContact: reviewCenterLocation.pointOfContact || '',
        phoneNumber: reviewCenterLocation.phoneNumber || '',
      },
      reviewersCount: this.role?.reviewersCount,
      roleType: roleType,
      scanForConflicts: scanForConflict,
      searchMarkets: (searchMarket || []).filter((market: any) => market.name !== 'All'),
      skills: this.role?.skills,
      status: this.role?.status,
      tsheet: this.role?.tsheet,
      workSchedule: {
        ...workSchedule,
        schedule: workSchedule.schedule,
        duration: selectedDuration?.value || this.schedulingForm.value?.workSchedule?.duration?.label || '',
        workHours: selectedWorkHours?.value || this.schedulingForm.value?.workSchedule?.workHours?.label || '',
      },
      educationNotRequired: this.getFormValue(this.informationForm, 'educationNotRequired')
    };

    if(isRemote){
      delete payload.reviewCenterLocation.reviewLocation;
    }

    if(criteriaTemplate){
      let templateId:any = [];
      let name:any = [];
      criteriaTemplate.map((d:any)=>{
        if(d && Object.keys(d).length > 0){
          templateId.push(d._id);
          name.push(d.name);
        }
      }) 
      payload.criteriaTemplate = templateId|| [];
      payload.criteria = [];
      if(dynamicRoleCriteriaTypes && dynamicRoleCriteriaTypes.length && !criteriaAnyArray){
        const criteria = [{
          name: name.join(',') || '',
          labels: {
            labelName: name.join(',') || '',
            values: (dynamicRoleCriteriaTypes || []).map((criteria: any) => {
              criteria.selected = true;
              return criteria;
            })
          }
        }];
        criteria[0].labels.values = criteria[0]?.labels?.values.flatMap((arr: any) => arr).filter((obj: any) => obj && obj.val);
        payload.criteria = criteria
      }
    }

    if(credentialTemplate){
      let templateId:any = [];
      credentialTemplate?.map((d:any)=>{
        if(d && Object.keys(d).length > 0){
          templateId.push(d?._id);
        }
      }) 
      payload.credentialTemplate = templateId || [];
      payload.credential = []
      if(dynamicCredentialTypes && dynamicCredentialTypes.length && !credentialAnyArray){
        const credTypes = (dynamicCredentialTypes).map((creds: any) => {
          creds.selected === true;
          return creds;
        });
        payload.credential = credTypes.flat();
      }
    }

    return payload;
  }

  isScheduleChanged(tempSchedule: any) {

    if (tempSchedule && tempSchedule.old) {
        return JSON.stringify(tempSchedule.old) !== JSON.stringify(tempSchedule.new);
    }
    return false;
  }

  setTempSchedule(temp: any, type: any, schedule: any) {
    temp[type] = JSON.parse(JSON.stringify(schedule));
    Object.keys(temp[type]).forEach(function (key) {
        if (!temp[type][key].willWork) {
            temp[type][key].time = {};
        }
    });
  }

  getCreatePayload(location: any): any{
    const workSchedule = this.getWorkSchedule();
    this.setTempSchedule(this.tempSchedule, 'new', workSchedule.schedule);

    const isCyber = this.project?.projectType === constants.projectType.CYBER;

    const graduateDegrees = this.informationForm.controls['graduate'].getRawValue();
    const undergraduateDegrees = this.informationForm.controls['underGraduate'].getRawValue();

    const criteriaArray = this.dynamicCriterias.controls;
    const criteriaTemplate = criteriaArray.map((control: any) => control?.get('criteria').value);
    const dynamicRoleCriteriaTypes = criteriaArray.map((control:any) => control?.get('types').value);
    const criteriaAnyArray = criteriaArray.map((control:any) => control?.get('criteriaAny').value).every(element => element === true);


    const credentialsArray = this.dynamicCredentials.controls;
    const credentialTemplate = credentialsArray.map((control: any) => control?.get('credential').value);
    const dynamicCredentialTypes = credentialsArray.map((control:any) => control?.get('types').value);
    const credentialAnyArray = credentialsArray.map((control:any) => control?.get('credentialAny').value).every(element => element === true);

    const searchMarket = this.informationForm.controls['searchMarket'].getRawValue();
    const immigrationStatus = this.informationForm.controls['immigrationStatus'].getRawValue();

    const startDate = moment(this.schedulingForm.controls['startDate'].getRawValue()).format("MM/DD/YYYY");
    const startTime = this.schedulingForm.controls['startTime'].getRawValue();

    const startAt = moment(startDate + ' ' + moment(startTime).format('LTS')).format('YYYY-MM-DDTHH:mm:ss') + '.000Z';

    const displayStaffBy = moment(this.getFormValue(this.schedulingForm, 'displayStaffBy')).format('MM/DD/YYYY');
    const staffByTime = new Date();
    staffByTime.setHours(0, 0, 0);

    const tempStaffBy = moment(new Date(displayStaffBy));
    const tempStaffByTime = moment(staffByTime);

    const staffBy = moment.utc([
      tempStaffBy.year(), 
      tempStaffBy.month(), 
      tempStaffBy.date(), 
      tempStaffByTime.hour(), 
      tempStaffByTime.minute()
    ]).format();
    const roleType = this.informationForm.controls['roleType'].getRawValue();
    const jobType = this.informationForm.controls['jobType'].getRawValue();
    const timezone = this.schedulingForm.controls['timezone'].getRawValue();
    const reviewCenterLocation = this.informationForm.controls['reviewCenterLocation'].getRawValue();
    const selectedDuration = this.schedulingForm.controls['duration'].getRawValue();
    const selectedWorkHours = this.schedulingForm.controls['workHours'].getRawValue() || '';
    const hasConflict = this.screeningForm.controls['hasConflict'].getRawValue();
    const scanForConflicts = this.getFormValue(this.screeningForm, 'scanForConflicts');
    if(scanForConflicts){
      scanForConflicts.specificQuestions = !!hasConflict;
    }

    const createPayload: any = {
      notifications: {
        schedule: this.isScheduleChanged(this.tempSchedule)
      },
      barMembership: {
        type: isCyber ? 'none' : 'any',
        skills: []
      },
      undergraduateDegrees: this.informationForm.get('educationNotRequired.underGrad')?.value ? [] : undergraduateDegrees,
      graduateDegrees: this.informationForm.get('educationNotRequired.grad')?.value ? [] : graduateDegrees,
      foreignLanguageExperiences: [],
      reviewExperiences: [],
      softwareExperiences: [],
      contractExperiences: [],
      industryExperiences: [],
      certifications: [],
      searchMarkets: (searchMarket || []).filter((market: any) => market.name !== 'All'),
      trainings: [],
      cyberSkills: [],
      cyberExperiences: [],

      immigrationStatus,
      startAt,
      displayStaffBy,
      staffByTime,
      staffBy,
      degrees: this.informationForm.get('educationNotRequired.underGrad')?.value && this.informationForm.get('educationNotRequired.grad')?.value ? [] : graduateDegrees.concat(undergraduateDegrees),
      experiences: [],
      skills: [],
      reviewersCount: this.occupiedReviewersCount || 0,
      roleType,
      jobType: jobType.length? jobType?.map((jt: any) => jt.name) : [],
      criteria: [],
      timezone: timezone?.id || '',
      reviewCenterLocation: {
        ...reviewCenterLocation, 
        reviewLocation: location?._id || this.role?.reviewCenterLocation.reviewLocation,
        isRemote: reviewCenterLocation.isRemote || this.getFormValue(this.informationForm, 'isRemote'),
        email: reviewCenterLocation.email || '',
        instructions: reviewCenterLocation.instructions || '',
        pointOfContact: reviewCenterLocation.pointOfContact || '',
        phoneNumber: reviewCenterLocation.phoneNumber || '',
      },
      workSchedule: {
        schedule: workSchedule.schedule,
        duration: selectedDuration?.value || this.schedulingForm.value?.workSchedule?.duration?.label || '',
        workHours: selectedWorkHours?.value || this.schedulingForm.value?.workSchedule?.workHours?.label || '',
      },
      hasConflict,
      scanForConflicts,
      conflictQuestions: this.conflictQuestions.slice(),
      educationNotRequired: this.getFormValue(this.informationForm, 'educationNotRequired')
    };
    
    if(criteriaTemplate){
      let templateId:any = [];
      let name:any = [];
      criteriaTemplate.map((d:any)=>{
        if(d && Object.keys(d).length > 0){
          templateId.push(d._id);
          name.push(d.name);
        }
      }) 
      createPayload.criteriaTemplate = templateId|| [];
      createPayload.criteria = [];
      if(dynamicRoleCriteriaTypes && dynamicRoleCriteriaTypes.length && !criteriaAnyArray){
        const criteria = [{
          name: name.join(',') || '',
          labels: {
            labelName: name.join(',') || '',
            values: (dynamicRoleCriteriaTypes).map((criteria: any) => {
              criteria.selected = true;
              return criteria;
            })
          }
        }];
        criteria[0].labels.values = criteria[0]?.labels?.values.flatMap((arr: any) => arr).filter((obj: any) => obj && obj.val);
        createPayload.criteria = criteria
      }
    }

    if(credentialTemplate){
      let templateId:any = [];
      credentialTemplate.map((d:any)=>{
        if(d && Object.keys(d).length > 0){
          templateId.push(d?._id);
        }
      }) 
      createPayload.credentialTemplate = templateId || [];
      createPayload.credential = [];
      if(dynamicCredentialTypes && dynamicCredentialTypes.length && !credentialAnyArray){
        const credTypes = (dynamicCredentialTypes).map((creds: any) => {
          creds.selected === true;
          return creds;
        });
        createPayload.credential = credTypes.flat();
      }
    }

    return createPayload;
  }

  onSubmitInformationForm(): void {
    this.submitted = true;

    if(!this.informationForm.valid || !this.isReviewerValidated() || !this.isDegreeValidated() || !this.eitherPhoneNumberAndEmailNotEmpty()){
      return;
    }
    this.submitted = false;
    this.activeIndex = 1;
    this.isInformationFormValid = true;
  }

  onSubmitSchedulingForm(): void {
    this.submitted = true;

    if(!this.schedulingForm.valid){
      return;
    }
    this.submitted = false;

    this.activeIndex = 2;
    this.isSchedulingFormValid = true;
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if(!this.informationForm.valid || !this.schedulingForm.valid || !this.screeningForm.valid ){
      return;
    }

    this.submitted = false;
    this.isSchedulingFormValid = false;
    this.isInformationFormValid = false;

    

    if(this.roleId){
      const location = await this.saveReviewerLocation();
      const payload = this.getEditPayload(location);
      this.roleService.updateProjectRole(this.project?._id || '', this.roleId || '', payload).subscribe({
        next: (response) => {
          this.toast.showSuccess('Role updated successfully.');
          this.onCancel();
          this.activeExistingProjectRoles = [];
          this.expandedRows = {};
          this.getProjectRoles();
        },
        error: () => {
        }
      });
    } else {
      if(this.activeExistingProjectRoles && this.activeExistingProjectRoles.length){
      const oldConflictQuestions = JSON.stringify(this.roleDataConflictQuestions.conflictQuestions);
      const currentConflictQuestions = JSON.stringify(this.conflictQuestions);
      const oldworkSchedule = JSON.stringify(this.roleDataScheduling.workSchedule?.schedule);
      const currentworkSchedule = JSON.stringify(this.getWorkSchedule().schedule);
      if(
        this.checkIfDatesAreEqual(this.getFormValue(this.schedulingForm, 'startDate'), this.roleDataScheduling.startAt) || 
        this.checkIfDatesAreEqual(this.getFormValue(this.schedulingForm, 'displayStaffBy'), this.roleDataScheduling.staffBy) ||
        oldworkSchedule !== currentworkSchedule || 
        currentConflictQuestions !== oldConflictQuestions || 
        this.getFormValue(this.schedulingForm, 'timezone') !== this.roleDataScheduling.timezone
      ) {
          this.applyScheduling = true;
          this.applyConflictQuestions = true;
          this.visibleSaveModal = true;
      }
      } else {
        this.saveRole();
      }
      
    }

    
  }

  async saveRole(): Promise<void>{
    this.visibleSaveModal = false;
    const location = await this.saveReviewerLocation();
    const payload = this.getCreatePayload(location);
    payload.applyScheduling = this.applyScheduling;
    payload.applyConflictQuestions = this.applyConflictQuestions;
    this.roleService.createProjectRole(this.project?._id || '', payload).subscribe({
      next: (response) => {
        this.toast.showSuccess('Role added successfully.');
        this.onCancel();
        this.activeExistingProjectRoles = [];
        this.getProjectDetails();
        this.getProjectRoles();
        
      },
      error: () => {

      }
    });
  }

  checkIfDatesAreEqual(firstDate: any, secondDate: any) {
    const firstDateString = moment(firstDate).utc().format("MM/DD/YYYY HH:mm");
    const secondDateString = moment(secondDate).utc().format("MM/DD/YYYY HH:mm");
    return firstDateString !== secondDateString;
  }

  goToPrevious(index: number): void {
    this.changeTab.emit(index);
  }

  async publish(requestState: string): Promise<void>{
    if (!this.projectRoles.length) {
      this.toast.showError('Please add a project role');
      return;
    }
    if (!this.isStartDateWithin7Days && this.isCreated){
        this.toast.showError('Project start date must be within 14 days of current date before it could be published.');
        return;
    }
    if (this.isStartDateWithin7Days && this.isCreated && this.validateRolesStartDate(this.role).length) {
        this.toast.showError('Role start date cannot be less than project start date.');
        return;
    }

    this.confirmPublish(true);
  }

  async saveAndLogout(): Promise<void>{
    const currentProjectSteo = Number(this.project?.step || 0);
    if (currentProjectSteo < 5) {
      const pId = this.projectId || '';
      this.projectNavigation.setCurrentStep(4);
      await this.projectService.updateProjectStep(pId, 4);
      this.goToProjectListing();
    } else {
        this.projectNavigation.setCurrentStep(currentProjectSteo);
        this.goToProjectListing();
    }
  }

  async confirmPublish(confirm = false): Promise<void> {
    this.visiblePublishModal = false;
    if(!confirm){
      return
    }

    const projectId = this.projectId || '';
    this.disablePublishButton = true;
    try {
      await this.projectService.publishProject(projectId);
      const nextStep = 7;
      this.projectNavigation.setCurrentStep(7);
      try {
        this.projectService.updateProjectStep(projectId, nextStep);
        this.updateProjectState.emit();
        this.toast.showSuccess('Project published successfully.');
        this.isCreated = false;
        this.changeTab.emit(3);
      } catch (error) {
        this.toast.showSuccess('Error saving project roles');
      }
    } catch (error: any) {
      this.disablePublishButton = false;
      this.toast.showError(error?.data?.message || 'Something went wrong');
    }
  }

  validateRolesStartDate(roles: any): Array<any> {
    const invalidRoles: Array<any> = [];
    return invalidRoles;
  }

  async next(requestState: string): Promise<void> {
    if (!this.projectRoles.length) {
      this.toast.showError('Please add a project role');
      return;
    }

    if (Number(this.project?.step || 0) < 6) {

        let nextStep;
        if (requestState === 'review') {
            nextStep = 7;
        } else if (requestState === 'save') {
            nextStep = 5;
        } else {
            nextStep = 6;
        }
        this.projectNavigation.setCurrentStep(nextStep);

        try {
          await this.projectService.updateProjectStep(this.projectId || '', nextStep);
          this.getProjectDetails();
          this.changeTab.emit(4);
        } catch (error) {
          this.toast.showError('Error saving project roles');
        }
    } else {
        this.projectNavigation.setCurrentStep(Number(this.project?.step || 0));
        this.goToNextStep(requestState);
    }
  }

  goToNextStep(requestState: string) {
    if (requestState === 'next') {
      this.changeTab.emit(3);
    }
    else if (requestState === 'review') {
      this.changeTab.emit(4);
    }
    else if (requestState === 'save') {
        this.goToProjectListing();
    }
  }

  goToProjectListing(): void {
    switch (this.project?.status) {
        case constants.projectStatus.created:
            this.router.navigate([`/client/project/unpublished`]);
            break;
        case constants.projectStatus.published:
            this.router.navigate([`/client/project/published`]);
            break;
        case constants.projectStatus.started:
            this.router.navigate([`/client/project/started`]);
            break;
        case constants.projectStatus.archived:
            this.router.navigate([`/client/project/archived`]);
            break;
        default:
            this.router.navigate([`/client/overview`]);
    }
  }

  onEdit(roleId: string) {
    this.onCreate();
    this.btnlabel = 'SAVE';
    this.roleId = roleId;
    this.getRole();

  }
  
  onCancel(): void {
    this.btnlabel = 'Add Role';
    this.showCreateForm = false;
    this.roleId = null;
    this.role = null;
    this.activeIndex = 0;
    this.schedulingForm.get('startDate')?.enable();
    this.schedulingForm.get('startTime')?.enable();
    this.schedulingForm.get('timezone')?.enable();
    this.schedulingForm.get('displayStaffBy')?.enable();
    this.resetForm();
    this.clearLocation();
  }

  resetForm(): void{
    const displayStaffBy = new Date();
    this.credentialOptions = [];
    this.criteriaOptions = [];
    this.dynamicCredentials.clear();
    this.dynamicCriterias.clear();
    this.informationForm.reset({
        roleType: '',
        jobType: '',
        searchMarket: '',
        underGraduate: [],
        graduate: [],
        dynamicCredential: this.fb.array([]),
        dynamicCredentialTypes: [],
        dynamicRoleCriteria: null,
        dynamicRoleCriteriaTypes: [],
        immigrationStatus: 'any',
        isRemote: true,
        reviewCenterLocation: {
            pointOfContact: '',
            email: '',
            phoneNumber: '',
            instructions: ''
        },
        underGraduateAny: true,
        graduateAny: true,
        criteriaAny: true,
        credentialAny: true,
        educationNotRequired: {
          grad: true,
          underGrad: true
        },
        selectedGraduateEducation: this.education[0],
        selectedunderGradEducation: this.education[0]
      });
    this.schedulingForm.reset({
        startDate: moment(new Date()).utc().toDate(),
        startTime: this.getDateTimeObjWithTime(9, 0),
        timezone: null,
        displayStaffBy: displayStaffBy,
        question: '',
        duration: null,
        workHours: null,
        workSchedule: {
          duration: '',
          schedule: '',
          workHours: ''
        },
        weekDaysWillWork: true,
        saturdayWillWork: false,
        sundayWillWork: false,
        weekdayStartTime: this.getDateTimeObjWithTime(9, 0),
        weekdayEndTime: this.getDateTimeObjWithTime(18, 0),
        saturdayStartTime: this.getDateTimeObjWithTime(9, 0),
        saturdayEndTime: this.getDateTimeObjWithTime(18, 0),
        sundayStartTime: this.getDateTimeObjWithTime(9, 0),
        sundayEndTime: this.getDateTimeObjWithTime(18, 0),
    });
    this.screeningForm.reset({
      scanForConflicts: [{
        fullCompanyNames: false,
        specificQuestions: false
      }],
      conflictQuestions: [],
      hasConflict: false,
    });
  }

  async onCreate() {
    this.showCreateForm = true;
    this.roleId = null;
    this.role = null;
    await this.getProjectActiveRoles();
  }  

  async getProjectActiveRoles(): Promise<void> {
    this.activeExistingProjectRoles = await this.projectService.getProjectActiveRoles(this.projectId || '');
    if(this.activeExistingProjectRoles && this.activeExistingProjectRoles.length){

      this.roleDataScheduling = this.activeExistingProjectRoles.find((role: any) => role.applyScheduling === true);
      this.roleDataConflictQuestions = this.activeExistingProjectRoles.find((role: any) => role.applyConflictQuestions === true);

     
      this.conflictQuestions = this.roleDataConflictQuestions?.conflictQuestions || [];
      this.screeningForm.controls['hasConflict'].setValue(!!this.roleDataConflictQuestions.hasConflict);
      this.screeningForm.controls['scanForConflicts'].setValue(this.roleDataConflictQuestions.scanForConflicts);
      
      this.setDefaultDurationAndWorkHourOnActiveRole();
      this.setDefaultWorkScheduleIfActiveRoleExists(); 
      if(this.checkIfProjectStartDateTimeLessThanCurrentDateTime(this.roleDataScheduling.startAt)){
        this.setFormValue(this.schedulingForm, 'startDate', null);
      } else {
        const startAt = this.roleDataScheduling.startAt ? moment(new Date(this.roleDataScheduling.startAt)).utc().format("MM/DD/YYYY") : '';
        this.setFormValue(this.schedulingForm, 'startDate', startAt);
      }

      if (this.checkIfProjectStartDateLessThanCurrentDate(this.roleDataScheduling.staffBy)) {
        this.schedulingForm.controls['displayStaffBy'].setValue(null);
      } else {
          this.schedulingForm.controls['displayStaffBy'].setValue(this.roleDataScheduling.staffBy ? moment(new Date(this.roleDataScheduling.staffBy)).utc().format("MM/DD/YYYY") : null);
      }

      this.setWorkSchedule(this.roleDataScheduling);
      this.roleMinDate = this.getRoleMinDate(this.isProjectStarted? this.projectStartDate : this.roleDataScheduling.startAt);
      this.roleMaxDate = this.getRoleMaxDate(this.isProjectStarted? this.projectStartDate : this.roleDataScheduling.startAt);
    }
  }

  setFormValue(form: FormGroup, controlName: string, value: any, emitEvent = true): void{
    form.controls[controlName].setValue(value, {emitEvent});
  }

  checkIfProjectStartDateTimeLessThanCurrentDateTime(projectStartDate: any): boolean {
    const projectDateString = moment(projectStartDate).utc().format("MM/DD/YYYY HH:mm");
    projectStartDate = moment(projectDateString, "MM/DD/YYYY HH:mm");

    const currentDateString = moment(new Date()).utc().format("MM/DD/YYYY HH:mm");
    const currentDate = moment(currentDateString, "MM/DD/YYYY HH:mm");

    return projectStartDate < currentDate;
  }

  checkIfProjectStartDateLessThanCurrentDate(projectStartDate: any) {

    const projectDateString = moment(projectStartDate).utc().format("MM/DD/YYYY");
    projectStartDate = moment(projectDateString, "MM/DD/YYYY");

    const currentDateString = moment(new Date()).utc().format("MM/DD/YYYY");
    const currentDate = moment(currentDateString, "MM/DD/YYYY");

    return projectStartDate < currentDate;
  }
 
  onRowExpand(event: TableRowExpandEvent) {
    this.projectService.getRoleDetails(this.projectId || '',event.data._id).subscribe((response)=>{
      this.roleDetails = response;
      this.roleDetailsDisplay = {
        jobType: this.roleDetails?.jobType,
        degrees: this.roleDetails?.degrees,
        graduate: this.roleDetails?.degrees.filter((degree) => degree.graduate),
        underGrad: this.roleDetails?.degrees.filter((degree) => !degree.graduate),
        skillsAndExperience: this.roleDetails?.criteria[0]?.labels?.values,
        credential: this.roleDetails?.credential,
      }
    });
    this.expandedRows = {};
    this.expandedRows[event.data._id] = true; 
  }
  onRowCollapse(event: TableRowCollapseEvent) {}

  setDisplayTimeFromWorkingHours(workHoursObj: any): any {

    let hours = workHoursObj.hour;
    const minutes = workHoursObj.min;
    const meridiem = workHoursObj.meridiem;

    if (meridiem === "pm") {
        if (hours !== 12) {
            hours += 12;
        }
    }
    if (hours === 12 && meridiem === "am") {
        hours = 0;
    }

    const time = this.getDateTimeObjWithTime(hours, minutes);

    return time;

  }

  getDateTimeObjWithTime(hours: number, mins: number) {

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins);
    return date;
  }

  getUnreadMessageCount(){
    this.firebaseMessagingService.getFirmUnReadMessages().subscribe((unReadDiscussions:any)=>{
      this.unReadDiscussions = unReadDiscussions;
    });
  }

  async openDeleteModal(data:any){
    this.visibleDeleteModal = true;
    this.selectedDeleteRole = data;
    this.isUnreadMessagesExist = !!(this.unReadDiscussions.find(discussion => {
      return discussion.roleId === this.selectedDeleteRole._id && discussion.pmUnReadMessageCount > 0;
    }));
    this.restartDate = moment(new Date()).utc().toDate();
  }

  hideDeleteModal(getRoleApi?:boolean){
    if(getRoleApi){
      this.getProjectRoles();
    }
    this.visibleDeleteModal = false;
  }
  deleteRole(){
    if(this.projectId){
      if(this.selectedDeleteRole && this.selectedDeleteRole.status === this.roleStatus.active){
        this.projectService.deleteProjectRole(this.projectId, this.selectedDeleteRole._id, this.deleteRoleComment).subscribe(async (resp:any)=>{
          this.toast.showSuccess('Role Deleted Successfully');
          if(this.selectedDeleteRole.isStarted && this.selectedDeleteRole.occupiedPositions){
            const roleReviewers = await this.roleService.getProjectRoleReviewers(this.projectId || '', this.selectedDeleteRole._id, true);
            if(roleReviewers && roleReviewers.reviewers.length){
              this.openRatingReviewerModal(roleReviewers);
            }
          }
          this.hideDeleteModal(true);
        })
      }else{
        const startTime = this.restartTime.toLocaleTimeString([], { hour12: false });
        const st = startTime.split(':');
        const start_date = this.restartDate;
        start_date.setHours(Number(st[0]));
        start_date.setMinutes(Number(st[1]));
        this.projectService.reactiveRole(this.projectId, this.selectedDeleteRole._id, start_date).subscribe((resp:any)=>{
          this.hideDeleteModal(true);
          this.toast.showSuccess('Role Active Successfully');
        
        })
      }
      
    } 
  }

  async openRatingReviewerModal(data: any): Promise<void>{
    const rateReviewerModalRef = this.dialogService.open(RateBulkFreelancersComponent, {
      header: 'Rate Freelancers',
      width: '50vw',
      height: '60vh',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        projectId: this.projectId,
        endRole: true,
        reviewers: data,
        codeName: this.project?.codeName,
        firmId: this.firmId
      }
    });

    rateReviewerModalRef.onClose.subscribe(async (modalResponse) => {
      if(modalResponse && modalResponse.isConfirm){
        try{
          await this.projectService.submitReviewerRating(this.projectId || '', modalResponse.roleReviewersRating);
          if(modalResponse.notes && modalResponse.notes.length){
            await this.notesService.bulkAdd(modalResponse.notes);
          }
          this.toastService.showSuccess('Reviewer ratings submitted successfully');
          this.hideDeleteModal(true);
        }catch(error: any){
          this.toastService.showError(error && error.message ? error.message : 'Some error has occurred')
        }
      }
    });
  }


  sendOffer(role: Role){
    this.sendOfferButtonFromRole.emit(3);
    this.roleService.role = role;
  }

  clearLocation(){
    
    this.firmLocation = {
      firm: '',
      location: {
        city: '',
        officeName: '',
        state: '',
        street: '',
        suiteNo: '',
        zipCode: ''
      },
      __v: '',
      _id: ''
    };
  }
  
  captionName(caption: any): string {
    return this.companies.filter((company: any) => company._id === caption)[0]?.name || caption
  }
}
