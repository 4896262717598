import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { ToastService } from '../../../../../shared/services/toast.service';
import { Router } from '@angular/router';
import { PeopleService } from '../../../../../shared/services/people.service';
import { UserService } from '../../../../../shared/services/user.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ShareableInviteComponent } from '../modals/shareable-invite/shareable-invite/shareable-invite.component';
import { BulkInviteComponent } from '../modals/bulk-invite/bulk-invite/bulk-invite.component';
import { constants } from '../../../../../shared/constants/constants';
import { BulkInviteReponseComponent } from '../modals/bulk-invite/bulk-invite-response/bulk-invite-response.component';
import { JobTypeService } from '../../../../../shared/services/job-type.service';
import { SearchMarketService } from '../../../../../shared/services/search-market.service';
import { JobType } from '../../../../../shared/interfaces/settings.interface';
import { SearchMarket } from '../people.interface';
import { UserClientVm } from '../../../../../store/user/user.interface';

@Component({
  selector: 'app-invite-candidate',
  standalone: true,
  imports: [
    FloatLabelModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './invite-candidate.component.html',
  styleUrl: './invite-candidate.component.scss'
})
export class InviteCandidateComponent {

  inviteForm!: FormGroup;
  visible: boolean = false;
  ref: DynamicDialogRef | undefined;
  jobTypes: Array<JobType> = [];
  searchMarkets: Array<SearchMarket> = [];
  user: UserClientVm | null = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialogService: DialogService,
    private peopleService: PeopleService,
    private userService: UserService,
    private toastService: ToastService,
    private jobTypeService: JobTypeService,
    private searchMarketService: SearchMarketService
  ) {}

  ngOnInit() {
    this.inviteForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.pattern(constants.emailRegex)]],
      phone: [''],
    });
    this.user = this.userService.getSessionUser();
    this.getFirmJobType();
    this.getFirmSearchMarkets();
  }

  getFirmJobType() {
    this.jobTypeService.getFirmJobType(this.user?.firm._id || '', true).subscribe({
      next: (jobTypes) => {
        this.jobTypes = jobTypes;
      },
    });
  }

  getFirmSearchMarkets() {
    this.searchMarketService
      .getFirmSearchMarkets(this.user?.firm._id || '')
      .subscribe({
        next: (searchMarkets) => {
          this.searchMarkets = searchMarkets;
        },
      });
  }

  isJobTypeAvailable(){
    return this.jobTypes && this.jobTypes.length;
  }

  isSearchMarketAvailable(){
    return this.searchMarkets && this.searchMarkets.length;
  }

  validatedInvite(): boolean{

    if(!this.isJobTypeAvailable() && !this.isSearchMarketAvailable()){
      this.toastService.showError('Please add at least one job type and search market to invite candidate');
      return false;
    }

    if(!this.isJobTypeAvailable()){
      this.toastService.showError('Please add at least one job type to invite candidate');
      return false;
    }
    if(!this.isSearchMarketAvailable()){
      this.toastService.showError('Please add at least one search market to invite candidate');
      return false;
    }

    return true;
  }

  onSubmit() {
    if(!this.validatedInvite()){
      return;
    }
    if (this.inviteForm.valid) {
      let data = {
        ...this.inviteForm.value,
        phone: this.inviteForm.controls['phone'].value.replace(/-/g, '')
      };
      let body = {
        firmId: this.userService.getUserFirmId(),
        users: [data]
      };
      this.peopleService.inviteCandidates(body).subscribe((response: any) => {
          this.toastService.showSuccess('Invite Sent Successfully');
          this.inviteForm.reset();
      });
    }
  }

  back() {
    this.router.navigate([`/client/people/all`]);
  }

  showShareableInviteDialog($event: Event) {
    if(!this.validatedInvite()){
      return;
    }
    $event.stopPropagation();
    this.ref = this.dialogService.open(ShareableInviteComponent, {
      header: 'Shareable Invite Link',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
  }

  showBulkInviteDialog() {
    if(!this.validatedInvite()){
      return;
    }
    const bulkInviteRef: DynamicDialogRef = this.dialogService.open(BulkInviteComponent, {
      header: 'Bulk Invite Candidates',
      width: '80vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    bulkInviteRef.onClose.subscribe((response: any) => {
      if(response){
        this.dialogService.open(BulkInviteReponseComponent, {
          header: 'Bulk Invite Summary',
          width: '80vw',
          contentStyle: { overflow: 'auto' },
          breakpoints: {
            '960px': '75vw',
            '640px': '90vw'
          },
          data: {
            users: response.users && response.users.length ? response.users : []
          }
        })
      }
    })

  }

  hideDialog(){
    this.visible = false;
  }

  getFormValue(value: string) {
    return this.inviteForm.get(value);
  }

  formatContact(event: any): void {
    let input = event.target.value.replace(/\D/g, '');
    let formattedInput = '';
    if (input.length > 0) {
      formattedInput = input.substring(0, 3);
  }
  if (input.length > 3) {
      formattedInput += '-' + input.substring(3, 6);
  }
  if (input.length > 6) {
      formattedInput += '-' + input.substring(6, 10);
  }
    event.target.value = formattedInput;  
  }
}
