import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../../../../../shared/services/project.service';
import { ResponseTabComponent } from './response-tab/response-tab.component'
import { DetailsTabComponent } from './details-tab/details-tab.component';
import { DocumentService } from '../../../../../shared/services/document-service';
import { StaffTabComponent } from './staff-tab/staff-tab.component';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentEditComponent } from './modals/document-edit/document-edit.component';
import { CompanyService } from '../../../../../shared/services/company.service';
@Component({
  selector: 'app-details',
  standalone: true,
  imports: [MultiSelectModule, FloatLabelModule, DialogModule, CheckboxModule, TabViewModule, DropdownModule, ButtonModule, 
    FormsModule, ChipModule, IconFieldModule, InputIconModule, HeadingComponent, InputTextModule, TableModule, CommonModule, 
    StaffTabComponent, ResponseTabComponent, DetailsTabComponent, TooltipModule],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService, DynamicDialogRef, DynamicDialogConfig],

})
export class DetailsComponent implements OnInit {

  products: any;
  checked: boolean = false;
  expandedRows = {};
  visible: boolean = false;
  headingButton = {
    url: '',
    label: 'EDIT PROJECT',
    icon: 'pi pi-pencil'
  }
  projectId!: string | null;
  projectDetails! : any;
  projectStaffList: any;
  projectPositionsListed: number = 0;
  projectPositionsFilled: number = 0;
  companies: any = [];
  ref: DynamicDialogRef | undefined;

  constructor(private route: ActivatedRoute,
    private projectService: ProjectService,
    private documentService: DocumentService,
    public dialogService: DialogService,
    private companyService: CompanyService,
  ) {}

  showDialog() {
    this.visible = true;
  }

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.headingButton.url = `/client/project/${this.projectId}/edit`;
    this.getProjectStaffList();
    this.getProjectDetails();
    this.companies = await this.companyService.getCompanies();
  }

  getProjectDetails(): void {
    if (this.projectId){
      this.projectService.getProjectById(this.projectId).subscribe({
        next: (resp) => {
          if(resp.documents.length > 0){
            resp.documents.forEach((element: { url: string | null; _id: string; }) => {
              element.url = this.documentService.getDocumentPathById(element._id);
            });
          }
          this.projectDetails = resp;
        }
      })
    }
  }

  getProjectStaffList(): void {
    this.projectStaffList = {};
    if (this.projectId)
      this.projectService.getProjectStaffList(this.projectId).subscribe({
        next: (resp) => {
          this.projectPositionsListed = resp.positionsListed;
          this.projectPositionsFilled = resp.positionsFilled;
          this.projectStaffList = resp.allStaff;
          this.projectStaffList.map((role: any) => {
            role.currentlyAssigned = [];
            role.formerlyAssigned = [];
            if (role.positionsList && role.positionsList.length) {
              role.positionsList.map((position: any) => {
                if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                  role.currentlyAssigned.push(position);
                }
                else {
                  role.formerlyAssigned.push(position);
                }
              });
            }
          });
        }
      })
  }

  downloadDocument(document:any){
    window.open(document.url, '_blank')
  }

  getModalConfig(data?: any) {
    return {
      header: 'DOCUMENTS',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: { files: data, projectId: this.projectId}
    };
  }

  openDocumentEditModal($event: Event, documents: any[]){
    $event.stopPropagation();
    this.ref = this.dialogService.open(
      DocumentEditComponent,
      this.getModalConfig(documents)
    );

    this.ref.onClose.subscribe((data) => {
        this.getProjectDetails();
    });

  }

  onRowExpand(event: TableRowExpandEvent) { }

  onRowCollapse(event: TableRowCollapseEvent) { }

  updateAndReInitializeStaff(): void{
    this.getProjectStaffList();
    this.getProjectDetails();
  }

  captionName(caption: any): string {
    return this.companies.filter((company: any) => company._id === caption)[0]?.name || caption
  }
}
