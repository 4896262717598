import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentService } from '../../../../shared/services/document-service';
import { environment } from '../../../../../environments/environment';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollerModule } from 'primeng/scroller';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
@Component({
  selector: 'app-view-docusign-documents-modal',
  standalone: true,
  imports: [ButtonModule,FormsModule, RadioButtonModule, DropdownModule, DividerModule, TooltipModule, ScrollerModule],
  templateUrl: './view-docusign-documents-modal.component.html',
  styleUrl: './view-docusign-documents-modal.component.scss'
})
export class ViewDocusignDocumentsComponent implements OnInit {
  selectedRole : any;
  documentInfo: any = {
    documents: [], 
    envelopeId: '',
    status: '',
    additionalEnvelopes: [],
    isReviewerModal: true
  }; 
  todoListItem: any;
  constructor(private ref: DynamicDialogRef, 
    private reviewerService: ReviewerService, 
    public config: DynamicDialogConfig, private documentService: DocumentService) {}

  ngOnInit(): void {
    this.documentInfo = this.config?.data;
    this.todoListItem = this.documentInfo.todoListItem;
  }

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    this.ref.close(null);
  }

  async completeAdditionalDocument(additionalEnvelope: any){
    const clientHostUrl = environment.clientHost;
    const envelopRecipient = await this.documentService.getEnvelopeRecipientView(additionalEnvelope.envelopeId, clientHostUrl);
    window.open(envelopRecipient.url,"open");
    this.updateTodoListItem();
  }

  async completeDocument() {
    const clientHostUrl = environment.clientHost;
    const envelopRecipient = await this.documentService.getEnvelopeRecipientView(this.documentInfo.envelopeId, clientHostUrl);
    window.open(envelopRecipient.url,"open");
    this.updateTodoListItem();
  }

  async viewDocument(documentId: string, documentName: string) {
    const res = await this.documentService.viewEnvelopeDocument(this.documentInfo.envelopeId, documentId);
    const url = URL.createObjectURL(new Blob([res]));
    const response = await fetch(url);
    const blob = await response.blob();
    const pdfContent = await this.documentService.getFileReaderObject(blob);

    const uri = `data:application/octet-stream,${window.escape(pdfContent)}`;

    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = documentName+'.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  updateTodoListItem(){
    if(this.todoListItem){
      this.reviewerService.updateNotificationHistory({id: this.todoListItem._id}, 'complete').subscribe(() => {});
    }
  }
}
