<!-- <div class="table-options field">
    <p-iconField iconPosition="right">
        <p-inputIcon styleClass="pi pi-search" />
        <input type="text" pInputText placeholder="Search Keywords " />
    </p-iconField>
</div> -->
<p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="responseData" dataKey="_id"
    [tableStyle]="{ 'min-width': '60rem' }" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
    (onRowCollapse)="onRowCollapse($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>
                Role

            </th>
            <th>
                Position

            </th>
            <th>
                Last Offer Sent

            </th>
            <th>
                Offers Sent
            </th>
            <th style="width: 8%">Action</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" class="border-0" let-responseData let-expanded="expanded">
        <tr>
            <td>{{ responseData.roleType }}</td>
            <td> {{ responseData.positions }} Listed / {{ responseData.positionsFilled }} Filled</td>
            <td>
                @if (responseData.lastOfferSent) {
                {{responseData.lastOfferSent | date:'h:mma MM/dd/yyyy ' }}
                } @else {
                -
                }
            </td>
            <td>{{ responseData.offerQueuesCount }}</td>
            <td>
                <div class="wrap-actions flex align-items-center gap-2">

                    @if(!responseData.isArchived){
                        <div [pTooltip]="getCancelButtonTooltip(responseData)" tooltipPosition="top">
                            <button [disabled]="responseData.disableCancelOfferButton" pButton pRipple type="button" (click)="cancelAllOutstandingOffer($event, responseData)"
                                class="p-button-rounded p-button-text">
                                <span [ngClass]="{'cancel-disabled-outstanding p-disabled': responseData.disableCancelOfferButton, 'cancel-outstanding': !responseData.disableCancelOfferButton}"> </span>
                            </button>
                        </div>
                    }

                    @if(!responseData.isArchived && user.role === 'FIRM_MANAGER' && (responseData.offers.unViewed.length || responseData.offers.viewed.length || responseData.offers.rejected.length)){
                        <button pTooltip="Download" (click)="downloadCSV(responseData.offers, responseData.roleType)" tooltipPosition="top" pButton pRipple type="button"
                            class="p-button-rounded p-button-text">
                            <span class="material-symbols-outlined"> download </span>
                        </button>
                    }

                    <p-button [pTooltip]="expanded? 'Close' : 'Expand'" tooltipPosition="top" type="button" pRipple
                        [pRowToggler]="responseData" [text]="true" [rounded]="true" [plain]="true" [icon]="
                expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
            " class="row-toggler toggler-btn" />
                </div>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-responseData>
        <tr>
            <td colspan="7" class="p-0">
                <div class="p-0 inner-wrap surface-100">
                    <p-table [value]="responseData.offerList" dataKey="_id">
                        <ng-template pTemplate="header">
        <tr>
            <th class="pl-40">
                Unviewed <span class="count">{{responseData.offers.unViewed.length}}</span>

            </th>
            <th>
                Viewed <span class="count">{{responseData.offers.viewed.length}}</span>

            </th>
            <th>
                Late Accept <span class="count">{{responseData.lateOfferAcceptedReviewers.length}}</span>
            </th>
            <th>
                Rejected <span class="count">{{responseData.offers.rejected.length}}</span>

            </th>
        </tr>
    </ng-template>
    <!--  <ng-template pTemplate="body" let-offerList>
        <tr>
            <td class="pr-40">
                <span class="tag-value rejected-status">


                    {{ offerList.status }}
                </span>
            </td>
        </tr>
    </ng-template> -->
    <ng-template pTemplate="body" let-offerList>

        <tr class="expandedItem">
            <td class="pr-40">
                @for (uv of offerList.unViewed; track uv) {
                <ul>
                    <li class="flex align-items-center gap-2">
                        <span class="cursor-pointer" (click)="openReviewerDialog(uv.reviewer._id)">
                            {{uv.reviewer.firstName}} {{uv.reviewer.lastName}}
                        </span>
                        <div pTooltip="Cancel Offer" tooltipPosition="top">
                            <button pButton pRipple type="button" (click)="cancelOffer($event, uv)"
                                class="p-button-rounded p-button-text">
                                <span class="cancel-outstanding"> </span>
                            </button>
                        </div>
                    </li>
                </ul>
                }

            </td>
            <td class="pr-40">
                @for (view of offerList.viewed; track view) {
                <ul>
                    <li class="flex align-items-center gap-2">
                        <span class="cursor-pointer" (click)="openReviewerDialog(view.reviewer._id)">
                            {{view.reviewer.firstName}} {{view.reviewer.lastName}}
                        </span>
                        <div pTooltip="Cancel Offer" tooltipPosition="top">
                            <button pButton pRipple type="button" (click)="cancelOffer($event, view)"
                                class="p-button-rounded p-button-text">
                                <span class="cancel-outstanding"> </span>
                            </button>
                        </div>
                    </li>
                </ul>
                }
            </td>
            <td class="pr-40">
                @for (late of offerList.lateOfferAcceptedReviewers; track late) {
                <ul>
                    <li>
                        <span class="cursor-pointer" (click)="openReviewerDialog(late?.reviewer._id)">
                            {{late?.reviewer?.firstName}} {{late?.reviewer?.lastName}}
                        </span>
                    </li>
                </ul>
                }
            </td>
            <td class="pr-40">
                @for (reject of offerList.rejected; track reject) {
                <ul>
                    <li>
                        <span class="cursor-pointer" (click)="openReviewerDialog(reject.reviewer._id)">
                            {{reject.reviewer.firstName}} {{reject.reviewer.lastName}}
                        </span>
                        <span class="tag-value rejected-status" [pTooltip]="reject.status" tooltipPosition="top">
                            {{ reject.rejectReason }}
                        </span>
                    </li>
                </ul>
                }
            </td>
        </tr>
    </ng-template>
</p-table>
</div>
</td>
</tr>
</ng-template>
</p-table>