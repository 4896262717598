<app-heading [title]="'Worked Together'" [showToggle]="false" [button]="headingButton"></app-heading>
<div class="projects-data">
    <div class="grid grid-nogutter">
        <div class="col-12 p-0">
            <div class="grid grid-nogutter">
                <div class="col-12 md:col-6 xl:col-6 lg:col-6 sm:col-12 p-0">
                    <div class="wrap-left">
                       <div class="grid grid-nogutter">
                            <div class="md:pr-1 sm:pr-0 col-12 md:col-4 sm:col-12 ">
                                <p-iconField iconPosition="right">
                                    <p-inputIcon (click)="onSearchClick()"  styleClass="pi pi-search" />
                                    <input [(ngModel)]="searchText" (keydown)="onEnter($event)" type="text" pInputText placeholder="Search" />
                                </p-iconField>
                            </div>
                            <div class="my-2 md:my-0 md:pl-2 sm:pl-0 col-12 md:col-4 sm:col-12">
                                <p-dropdown  [(ngModel)]="selectedSortOrder"  (onChange)="onSortChange($event)" [options]="sortings" optionLabel="name"
                                placeholder="Sort By" />

                                
                            </div>
                            @if(showMultiTenantFeature.enabled){
                                <div class="my-2 md:my-0 md:pl-2 sm:pl-0 col-12 md:col-4 sm:col-12">
                                    <p-dropdown [(ngModel)]="selectedUserFilter"  (onChange)="onFilterChange($event)" [options]="userFilters" optionLabel="name"
                                    placeholder="User Filter" />
                                </div>    
                            }
                            <div class="p-0 col-12 md:col-12 sm:col-12">
                                <ul class="chips">
                                    @if(displaySearchTextChip){
                                        <li><p-chip [label]="searchTextDisplaying" (onRemove)="onRemoveChip('searchText')" [removable]="true" /></li>
                                    }
                                    @if(selectedSortOrder && selectedSortOrder.name){
                                        <li><p-chip [label]="selectedSortOrder.name" (onRemove)="onRemoveChip('sortOrder')" [removable]="true" /></li>
                                    }
                                    @if(selectedUserFilter && selectedUserFilter.name){
                                        <li><p-chip [label]="selectedUserFilter.name"/></li>
                                    }
                                    </ul>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <div class="data-table">
                <p-table styleClass="p-datatable-sm p-datatable-sm--normal"
                [value]="reviewers" 
                [lazy]="true"
                [rows]="10"
                [paginator]="true"
                [totalRecords]="reviewerCount"
                (onLazyLoad)="getReviewers($event)"
                [showCurrentPageReport]="true" 
                [paginator]="true" 
                [tableStyle]="{ 'min-width': '50rem' }"
                    currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                    autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width:20%">
                                Name
                            </th>
                            <th style="width:20%">
                                Email
                            </th>
                            <th style="width:20%">
                                Rating
                            </th>
                            <th style="width:20%">
                              Project Together
                            </th>
                            <th>
                                Actions
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-reviewer>
                        <tr>
                            <td><span class="cursor-pointer" (click)="openReviewerDialog(reviewer._id)">
                                {{reviewer.firstName}} {{reviewer.lastName}}
                            </span></td>
                            <td>{{reviewer.email}}</td>
                            <td>
                                <p-rating [(ngModel)]="reviewer.averageRating" [readonly]="true" [cancel]="false" class="cursor-default" />
                            </td>
                            <td>{{reviewer.projectsTogether}}</td>
                            <td>
                                <div class="wrap-actions">
                                    <p-button pTooltip="Delete" tooltipPosition="top"  (click)="removeFromWorkTogetherList($event,reviewer)"   [rounded]="true" [text]="true" icon="true">
                                        <span class="red material-symbols-outlined">
                                            delete
                                        </span>
                                    </p-button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>


