import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-bulk-invite-response',
  standalone: true,
  imports: [
    ButtonModule,
    TableModule,
    TooltipModule
  ],
  templateUrl: './bulk-invite-response.component.html',
  styleUrl: './bulk-invite-response.component.scss'
})
export class BulkInviteReponseComponent {

  bulkInviteResponseInfo = {
    users: []
  }

  failedInvitedCandidates: Array<any> = [];
  successfulInvitedCandidates: Array<any> = [];

  constructor(
    private bulkInviteResponseRef: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.initialization();
  }
  
  initialization(): void {
    this.bulkInviteResponseInfo = {...this.config.data};
    this.getSuccessInvitedCandidates();
    this.geFailedInvitedCandidates();
  }

  onConfirm(): void{
    this.bulkInviteResponseRef.close();
  }

  getSuccessInvitedCandidates(): void{
    this.successfulInvitedCandidates = this.bulkInviteResponseInfo.users.filter((user: any) => user.inviteSent);
  }

  geFailedInvitedCandidates(): void{
    this.failedInvitedCandidates = this.bulkInviteResponseInfo.users.filter((user: any) => !user.inviteSent);
  }
}
