// message.component.ts
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputIconModule } from 'primeng/inputicon';
import { IconFieldModule } from 'primeng/iconfield';
import { FormsModule } from '@angular/forms';
import { TreeModule } from 'primeng/tree';
import { CommonModule, DatePipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FloatLabelModule } from "primeng/floatlabel"
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { ChipsModule } from 'primeng/chips';
import { FirebaseMessagingService } from '../../../../shared/services/firebase-messaging.service';
import { AngularFireModule } from '@angular/fire/compat';
import { ProjectService } from '../../../../shared/services/project.service';
import { UserService } from '../../../../shared/services/user.service';
import { map, combineLatest ,BehaviorSubject} from 'rxjs';
import { MessageCreateDialogData, ProjectItem, ReviewerItem, RoleItem } from './message.interface';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserClientVm } from '../../../../store/user/user.interface';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { CreateDiscussionComponent } from './modals/create-discussion/create-discussion.component';
import { ToastService } from '../../../../shared/services/toast.service';
import { constants } from '../../../../shared/constants/constants';
import { distinctUntilChanged } from 'rxjs/operators';
import { MessagesService } from '../../../../shared/services/messages-service.service';
import { TooltipModule } from 'primeng/tooltip';
import { AddTemplateComponent } from './modals/add-template/add-template.component';
import { ReviewerDetailDialogService } from '../../../../shared/services/reviewer-detail-dialog.service';
import { MessageComponentService } from './message.component.service';
import { DocumentService } from '../../../../shared/services/document-service';
@Component({
  selector: 'app-message',
  standalone: true,
  imports: [
    InputSwitchModule, 
    FormsModule, 
    IconFieldModule, 
    InputIconModule, 
    InputTextModule, 
    TreeModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextareaModule,
    ScrollPanelModule,
    FloatLabelModule,
    DialogModule,
    ChipsModule,
    AngularFireModule,
    ProgressSpinnerModule,
    BadgeModule,
    TooltipModule
  ],
  providers:[DatePipe],
  templateUrl: './message.component.html',
  styleUrl: './message.component.scss'
})
export class MessageComponent implements OnInit, OnDestroy {
  
  user!: UserClientVm;
  ref: DynamicDialogRef | undefined;
  messageObject: any = {};
  isFilterActive: boolean = true;
  projectMessageList: any;
  messagesList  = false;
  messageProjects: any = []; 
  selectedDiscussionNodeData: any;
  selectedDiscussionNode: any;
  discussionSubscriber: any = [];
  discussionThreadSubscriber: any =[];
  selectedDiscussion: any;
  discussionThread: any;
  discussions!: any[];
  discussionsView: boolean = false;
  threadView: boolean = false;
  fetchingDiscussions: boolean = false;
  fetchingThread: boolean = false;
  hideProjectsTreeView: boolean = false;
  roleDiscussions: any = [];
  projectBroadcastDiscussions: any = [];
  firmLogo: string | undefined;
  msgSenderReviewer: string = constants.messageType.reviewer;

  varA$ = new BehaviorSubject<number>(0); // Example observable
  varB$ = new BehaviorSubject<number>(0); // Another example observable
  
  constructor(private firebaseMessagingService:FirebaseMessagingService,
     private projectService: ProjectService,
     private userService: UserService,
     private dialogService: DialogService,
     private toast: ToastService,
     private messagesService: MessagesService,
     private datePipe: DatePipe,
     private reviewerDetailDialogService: ReviewerDetailDialogService,
     private messageComponentService: MessageComponentService,
     private documentService: DocumentService,
     ) { 
    
     }

  ngOnInit() {
    this.firebaseMessagingService.initFirebase();
    this.user = this.userService.getSessionUser();
    this.observeDiscussionsAndUpdateView();
    if(this.messageComponentService.hasPendingViewData()){
      this.hideProjectsTreeView = true;
      this.initiateOnlyDiscussionsView(this.messageComponentService.getPendingViewData());
    } else{
    this.projectService.getProjectsWithRolesReviewers(
      this.userService.getUserFirmId(),this.userService.getUserRole()).subscribe({
        next:(_projects)=>{
          this.projectMessageList = _projects;
          this.messageProjects = this.filterOccupiedProjects(this.generateProjectTree(this.projectMessageList));
         }
      })
    }
    this.firmLogo =  this.documentService.getDocumentPathById(this.user.firm.logo || '') || '';
  }
  ngOnDestroy(): void {
    
      this.discussionSubscriber.forEach((s:any)=>s.unsubscribe());
      this.discussionThreadSubscriber.forEach((s:any)=>s.unsubscribe());
      if(this.messageComponentService.hasPendingViewData()){
        this.messageComponentService.clearPendingViewData();
      }
    
  }
  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  markAllMessagesRead(){
    this.messagesService.markMyMessagesRead( this.userService.getUserFirmId()).subscribe(()=>{
      this.toast.showSuccess("Marked Successfully");
    });
  }

  observeDiscussionsAndUpdateView(){
    combineLatest([this.varA$, this.varB$])
    .pipe(      
      distinctUntilChanged(([prevA, prevB], [currA, currB]) => currA === prevA && currB === prevB), 
      map(([a, b]) => a) 
    )
    .subscribe((a) => {
      // Do something when `a` changes
      let _discussions = this.roleDiscussions.concat(this.projectBroadcastDiscussions);
      _discussions = _discussions.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
      this.discussions = _discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
      // Perform any actions or update your view
  });
  }

  onNodeClick(node: TreeNode) {
    this.discussions = [];
    this.ngOnDestroy();
    this.showDiscussionView();
    this.selectedDiscussionNodeData = node.data;
    this.selectedDiscussionNode = node;
    this.unsubAllDiscussions();
    this.unsubAllDiscussionThreads();
    this.getDiscussions(node.data.projectId,node.data.roleId,node.data._id);
  }

  initiateOnlyDiscussionsView(node:any){
    this.discussions = [];
    this.ngOnDestroy();
    this.showDiscussionView();
    this.selectedDiscussionNodeData = {
      ...node,
      firstName:node.reviewer.firstName,
      lastName:node.reviewer.lastName,
      _id:node.reviewer._id};
      
    this.selectedDiscussionNode = {
    key: node.reviewer._id,
    label: `${node.reviewer.firstName} ${node.reviewer.lastName}`,
    data: {...node.reviewer,...node},
    icon: 'person',
    status:node.reviewer.status,
    node_type:'REVIEWER'};  
    this.unsubAllDiscussions();
    this.unsubAllDiscussionThreads();
    this.getDiscussions(node.projectId,node.roleId,node.reviewerId);
  }

  onActiveFilterToggle(){
    if(this.isFilterActive){
      this.messageProjects = this.filterOccupiedProjects(this.generateProjectTree(this.projectMessageList));
      
    }else{
      this.generateProjectTree(this.projectMessageList);
    }
    
  } 

  generateProjectTree(projectList:any){
    const projectItems:ProjectItem[] = Object.values(projectList); 
    this.messageProjects.splice(0,this.messageProjects.length);
    projectItems.forEach((project,index)=>{
      this.messageProjects.push(this.messageComponentService.generateProjectNode(project,index));
    })
    return this.messageProjects;
  }

 filterOccupiedProjects(data:any) {
    return data
      .map((project:any) => {
        const filteredRoles = project.children
          .map((role:any) => {
            const filteredReviewers = role.children.filter(
              (reviewer:any) => reviewer.data.status === "OCCUPIED"
            );
  
            return filteredReviewers.length > 0
              ? { ...role, children: filteredReviewers }
              : null;
          })
          .filter((role:any) => role !== null);
  
        return filteredRoles.length > 0
          ? { ...project, children: filteredRoles }
          : null;
      })
      .filter((project:any) => project !== null);
  }



  goBackToDiscussionView(){
    this.discussionsView = true;
    this.threadView = false;
    this.unsubAllDiscussionThreads();
  }

  showDiscussionView(){
    this.discussionsView = true;
    this.threadView = false;
  }

  showThreadView(){
    this.discussionsView = false;
    this.threadView = true;
  }

  getDiscussions(projectId:string,roleId:string,reviewerId:string): void {
    this.fetchingDiscussions = true;
    this.discussionSubscriber.push(this.firebaseMessagingService.getFirmDiscussions(projectId, roleId, reviewerId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr = Object.values(snapshot);
          let discussions = arr.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
          this.roleDiscussions = discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
          this.escalateChangeOnA();
          this.fetchingDiscussions = false;
        }
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      }
    }));
    this.discussionSubscriber.push(this.firebaseMessagingService.getProjectBroadCastDiscussions(projectId, reviewerId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr = Object.values(snapshot);
          
          let discussions = arr.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
          this.projectBroadcastDiscussions = discussions.sort((a:any, b:any) => b.pmUnReadMessageCount - a.pmUnReadMessageCount);
          this.escalateChangeOnB();
        } 
       
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      }
    }));
  }
  unsubAllDiscussions(){
      this.discussionSubscriber.forEach((subscriber:any)=>{
          subscriber.unsubscribe();
      });
      this.discussionSubscriber = [];
  }
  unsubAllDiscussionThreads(){
     
    this.discussionThreadSubscriber.forEach((subscriber:any)=>{
        subscriber.unsubscribe();
    });
    this.discussionThreadSubscriber = [];
  }
  getDiscussionThread(discussion:any) {
    this.showThreadView();
    this.unsubAllDiscussionThreads();
    this.fetchingThread = true;
    this.discussionsView = false;
    this.selectedDiscussion = discussion;
    this.discussionThreadSubscriber.push(this.firebaseMessagingService.getThreadsByDiscussion(discussion.discusstionId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr:any = Object.values(snapshot)[0];
          this.discussionThread = arr;
          this.firebaseMessagingService.updateLastViewFirm(discussion.discusstionId);
          this.messagesService.markFirmUsersMessagesRead(discussion.projectId, discussion.roleId, discussion.firmId, discussion.discusstionId);
          
        } 
        this.fetchingThread = false;
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      },
      complete: ()=>{
       
      }
    }));
  }

objectKeys(threadObject:any){
    return Object.keys(threadObject);
}


editMessage(threadMessage:any,messageId:string): void {
  this.messageObject.content = threadMessage.content;
  this.messageObject.editMessageId = messageId;
  this.messageObject.messageInput = threadMessage.content;
  if (this.selectedDiscussion) {
    this.messageObject.isUpdateDiscussion = (this.selectedDiscussion.lastMessage === threadMessage.content && this.selectedDiscussion.lastMessageSender === 'FIRM');
  }
}

deleteMessage(threadMessage:any,messageId:string): void {
  this.messageObject.content = threadMessage.content;
  this.messageObject.deleteMessageId = messageId;
  this.messageObject.discussionId = this.selectedDiscussion.discusstionId;
  if (this.selectedDiscussion) {
    this.messageObject.isUpdateDiscussion = (this.selectedDiscussion.lastMessage === threadMessage.content && this.selectedDiscussion.lastMessageSender === 'FIRM');
  }
  if (this.selectedDiscussion) {
    this.firebaseMessagingService.findDiscussionOrDeleteMessages(messageId, this.messageObject, this.messageObject.isUpdateDiscussion);
  }
}
  sendMessage(): void {
    if (this.messageObject.messageInput) {
      if (!this.messageObject.editMessageId) {
        this.messageObject.msgDate = this.firebaseMessagingService.getFirebaseServerTimeStamp();
        this.messageObject.senderName = `${this.user.firstName} ${this.user.lastName}`;
        this.firebaseMessagingService.findDiscussionOrCreateMessages({
          disscussionId: this.selectedDiscussion.discusstionId,
          content: this.messageObject.messageInput,
          sender: 'FIRM',
          from: this.user._id,
          to: this.selectedDiscussionNodeData._id,
          projectId: this.selectedDiscussionNodeData.projectId,
          roleId: this.selectedDiscussionNodeData.roleId,
          firm: this.user.firm._id,
          msgDate: this.messageObject.msgDate
        });
      } else {
        this.firebaseMessagingService.findDiscussionOrUpdateMessages(this.messageObject.editMessageId, {
          disscussionId:  this.selectedDiscussion.discusstionId,
          content: this.messageObject.messageInput
        }, this.messageObject.isUpdateDiscussion);
      }
      this.resetMessageInput();
    }
  }
  
escalateChangeOnA() {
    this.varA$.next(this.varA$.getValue()+1);
}
escalateChangeOnB() {
  this.varB$.next(this.varB$.getValue()+1);
}  
resetMessageInput(){
  this.messageObject.messageInput = "";
}  

  
openCreateDiscussion(node:any): void {
    if(node.node_type !== 'REVIEWER'){  
      this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
    }else{
      this.firebaseMessagingService.getFirmDiscussionsOnce(node.data.projectId,node.data.roleId,node.data._id)
      .pipe(
        map(res => {
          let firmDiscussionSnapshots = res ? {...res} : null;
      
          if (firmDiscussionSnapshots && Object.keys(firmDiscussionSnapshots).length > 0) {
            this.discussions = [];
            this.unsubAllDiscussionThreads();
            this.unsubAllDiscussions();
            this.showDiscussionView();
            this.selectedDiscussionNodeData = node.data;
            this.getDiscussions(node.data.projectId,node.data.roleId,node.data._id);
            
          } else {
            this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
          }
        })
      )
      .subscribe({
        error: () => {
          this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
        }
      });
    }     
  }

  getReadAt(message:any){
    return message.isRead? "Read at :" + this.datePipe.transform(message.readAt, "hh:mm a MMM dd YYYY"):"";
  }

  createNewThread(node:any){
    this.messageComponentService.openNewMessageDialog(this.messageComponentService.generateDialogData(node));
  }



  addNewTemplate($event: Event): void{
    $event.stopPropagation();
     this.ref = this.dialogService.open(AddTemplateComponent,
      {
        header: 'Add New Template',
        width: '60vw',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      }
     )
  }
}


