<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
      <h1 class="h4 m-0">Invite Candidates</h1>
  </div>
  <div class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
    <p-button (click)="showBulkInviteDialog()" [label]="'Bulk Invite'" [rounded]="true" size="large"/>
    <p-button (click)="showShareableInviteDialog($event)" [label]="'Shareable Invite link'" [rounded]="true" size="large"/>
  </div>
</div>
<form [formGroup]="inviteForm">
  <div class="grid grid-nogutter">

    <div class="col-4 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="firstName" type="text" pInputText />
        <label for="firstName">First Name<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('firstName')?.dirty || getFormValue('firstName')?.touched) && getFormValue('firstName')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-4 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="lastName" type="text" pInputText />
        <label for="lastName">Last Name<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('lastName')?.dirty || getFormValue('lastName')?.touched) && getFormValue('lastName')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-4 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="email" type="email" pInputText />
        <label for="email">Email<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('email')?.dirty || getFormValue('email')?.touched) && getFormValue('email')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
      @if(getFormValue('email')?.errors?.['pattern']){
        <div class="p-error ">Invalid Email.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-4 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input (input)="formatContact($event)" maxlength="12" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" formControlName="phone" type="text" pInputText />
        <label for="phone">Phone</label>
      </p-floatLabel>
    </div>

    <div class="col-8"></div>

    <div>
      <p-button
        class="col-4 lg:col-4 xl:lg:col-4"
        (click)="back()"
        [rounded]="true"
      >
        Back
      </p-button>
      <p-button
        class="col-4 lg:col-4 xl:lg:col-4"
        (click)="onSubmit()"
        [rounded]="true"
        [disabled]="!inviteForm.valid"
      >
        Submit
      </p-button>
    </div>
  </div>
</form>
