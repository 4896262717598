import { Component, Input, OnInit } from '@angular/core';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { ResponseProject } from '../response-tab/response.interface';
import { Role } from '../../project.interface';
import { TooltipModule } from 'primeng/tooltip';
import { CompanyService } from '../../../../../../shared/services/company.service';

@Component({
  selector: 'app-details-tab',
  standalone: true,
  imports: [TableModule, ButtonModule, IconFieldModule, InputIconModule, CommonModule, TooltipModule],
  templateUrl: './details-tab.component.html',
  styleUrl: './details-tab.component.scss'
})
export class DetailsTabComponent implements OnInit {
  @Input() projectId!: any;
  roleDetails!: Role;
  responseData: ResponseProject=[];
  expandedRows:{ [key: string]: boolean }  = {};
  projectDetails:any;
  projectSearchMarkets:string='';
  roleDetailsDisplay!:any;
  companies: any = [];

  constructor(private projectService: ProjectService, private companyService: CompanyService) {
  }

  async ngOnInit() {
    this.getProjectDetails();
    this.getProjectResponse();
    this.companies = await this.companyService.getCompanies();
  }

  getProjectDetails(): void {
    if (this.projectId){
      this.projectService.getProjectById(this.projectId).subscribe({
        next: (resp) => {
          this.projectDetails = resp;
        }
      })
    }
  }

  onRowExpand(event: TableRowExpandEvent) {
    this.projectService.getRoleDetails(this.projectId,event.data._id).subscribe((response)=>{
      this.roleDetails = response;
      this.roleDetailsDisplay = {
        jobType: this.roleDetails?.jobType,
        degrees: this.roleDetails?.degrees,
        graduate: this.roleDetails?.degrees.filter((degree) => degree.graduate),
        underGrad: this.roleDetails?.degrees.filter((degree) => !degree.graduate),
        skillsAndExperience: this.roleDetails?.criteria[0]?.labels?.values,
        credential: this.roleDetails?.credential,
      }
    });
    this.expandedRows = {};
    this.expandedRows[event.data._id] = true; 
    
   }
  onRowCollapse(event: TableRowCollapseEvent) { 

  }
  displaySearchMarkets(searchMarkets: any[]){
      return searchMarkets.length >0 ? searchMarkets.map((s:any)=>{s.name}).join(', ') : null;
  }
  getProjectResponse(): void {
    if (this.projectId){
      this.projectService.getProjectResponse(this.projectId).subscribe({
        next: (resp: ResponseProject) => {
          this.responseData = resp;
          this.projectService.getRoleDetails(this.projectId,this.responseData[0]?._id).subscribe((response)=>{
            this.roleDetails = response;
          })
        }
      })
     }
  }

  formatTime(time: { hour: number, min: number, meridiem: string }): string {
    const formattedHour = time.hour < 10 ? `0${time.hour}` : time.hour;
    const formattedMin = time.min < 10 ? `0${time.min}` : time.min;
    return `${formattedHour}:${formattedMin} ${time.meridiem}`;
  }

  captionName(caption: any): string {
    return this.companies.filter((company: any) => company._id === caption)[0]?.name || caption
  }
}
