import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import moment from 'moment';
import { constants } from '../../../../../../../../shared/constants/constants';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'offer-confirmation-modal',
  providers: [DialogService],
  standalone: true,
  imports: [
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DropdownModule,
    CalendarModule,
    DividerModule
  ],
  templateUrl: './offer-confirmation-modal.component.html',
})
export class OfferConfirmationModalComponent implements OnInit {
  createForm!: FormGroup;
  reviewerName = '';
  reviewerId = '';
  currentUTCDate: any = moment.utc().format('YYYY-MM-DDTHH:mm');
  defaultRoleStart = constants.defaultRoleStartTime;
  formaterUTCDate: any = null;
  minDate: Date | null;
  isStartedRole:boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public config: DynamicDialogConfig
  ) {
    this.minDate = moment(new Date()).utc().toDate();
  }

  ngOnInit() {
    this.currentUTCDate = new Date(moment.utc().format('YYYY-MM-DDTHH:mm'));
    this.formaterUTCDate = new Date(this.currentUTCDate);
    this.formaterUTCDate.setHours(this.defaultRoleStart.hour);
    this.formaterUTCDate.setMinutes(this.defaultRoleStart.minute);
    this.reviewerId = this.config?.data?._id;
    this.reviewerName = this.config?.data?.fullName || this.config?.data?.firstName && this.config?.data?.lastName ? `${this.config?.data?.firstName} ${this.config?.data?.lastName}` : '';
    this.createForm = this.fb.group({
      positionStarted: [this.formaterUTCDate],
      reviewerId: [this.reviewerId, [Validators.required]],
      roleId: [this.config?.data?.roleId]
    });
    this.isStartedRole = this.config?.data?.isStarted;
  }

  closeDialog(reviewerId = false) {
    if(reviewerId){
      this.ref.close({
        ...this.createForm.value,
        positionStarted: moment(this.createForm.controls['positionStarted'].getRawValue()).format('YYYY-MM-DDTHH:mm:ss+00:00')
      });
      return;
    }
    this.ref.close();
  }

  showMsg(){
    if(this.createForm.value.positionStarted){
      return moment(this.createForm.controls['positionStarted'].getRawValue()).isAfter(moment.utc().format('YYYY-MM-DDTHH:mm'));
    }
    return false;
  }
}
