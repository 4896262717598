import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentService } from '../../../../shared/services/document-service';
import { environment } from '../../../../../environments/environment';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { KeyValue } from '@angular/common';
import { UserService } from '../../../../shared/services/user.service';
import { UserClientVm } from '../../../../store/user/user.interface';
import { constants } from '../../../../shared/constants/constants';
import { ToastModule } from 'primeng/toast';
import { ToastService } from '../../../../shared/services/toast.service';
import { ReviewerService } from '../../../../shared/services/reviewer.service';
@Component({
  selector: 'app-view-documents-modal',
  standalone: true,
  imports: [ButtonModule,FormsModule, RadioButtonModule, DropdownModule, DividerModule, TooltipModule, ToastModule],
  templateUrl: './view-documents-modal.component.html',
  styleUrl: './view-documents-modal.component.scss'
})
export class ViewDocumentsComponent implements OnInit {
  selectedRole : any;
  documentInfo: any = {
    position: null
  }; 
  todoListItem: any;
  user: UserClientVm | null = null;
  constructor(
    private ref: DynamicDialogRef, 
    public config: DynamicDialogConfig, 
    private reviewerService: ReviewerService, 
    private documentService: DocumentService, 
    private userService: UserService,
    private toastService: ToastService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.user = this.userService.getSessionUser();
    this.documentInfo = this.config?.data;
    this.todoListItem = this.documentInfo.todoListItem;
    this.documentKey().forEach(key => {
      this.documentInfo.position.docs.blank[key].isUploading = false;
    })
  }

  closeDialog(){
    this.ref.close();
  }

  confirm() {
    this.ref.close(null);
  }

  async completeAdditionalDocument(additionalEnvelope: any){
    const clientHostUrl = environment.clientHost;
    const envelopRecipient = await this.documentService.getEnvelopeRecipientView(additionalEnvelope.envelopeId, clientHostUrl);
    window.open(envelopRecipient.url,"open");
    this.updateTodoListItem();
  }

  async completeDocument() {
    const clientHostUrl = environment.clientHost;
    const envelopRecipient = await this.documentService.getEnvelopeRecipientView(this.documentInfo.envelopeId, clientHostUrl);
    window.open(envelopRecipient.url,"open");
    this.updateTodoListItem();
  }

  async viewDocument(documentId: string, documentName: string) {
    const res = await this.documentService.viewEnvelopeDocument(this.documentInfo.envelopeId, documentId);
    const url = URL.createObjectURL(new Blob([res]));
    const response = await fetch(url);
    const blob = await response.blob();
    const pdfContent = await this.documentService.getFileReaderObject(blob);

    const uri = `data:application/octet-stream,${window.escape(pdfContent)}`;

    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = documentName+'.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  getDocumentPathById(docId: string){
    window.open(this.documentService.getDocumentPathById(docId) || '', '_blank');
  }

  documentKey(): Array<string>{
    return Object.keys(this.documentInfo.position.docs.blank);
  }

  uploadDocument($event: Event, blankMaster: any, doc: any) {
    const files = ($event.target as HTMLInputElement)?.files;
    if (files && files.length) {
      let opts = {
          documentType: "FILLED_FORM",
          projectId: this.documentInfo?.position?.project?._id,
          documentId: blankMaster
      };
      if (this.isGreaterThanAllowedSize(files[0])) {
          this.toastService.showError(`Document size exceeding limit must be ${constants.documentUploadSizeInMbs } mb or less.`);
          return;
      }

      doc.isUploading = true;
      this.documentService.uploadDocument(files[0], opts).subscribe({
        next: async (resp) => {
          if (resp.message && resp.message === "OK") {
            this.toastService.showSuccess("Your paperwork was saved. Thanks!");
            this.updateTodoListItem();
            await this.getDocs();
          }
          doc.isUploading = false;
        },
        error: (err) => {
          if (err.data.httpStatus === 400 && err.message) {
            this.toastService.showError( err.message);
          }
          else {
              this.toastService.showError("Error uploading document");
          }
          doc.isUploading = false;
        },
      });
    }
  }

  deleteDocument(docId: string){
    this.documentService.deleteDocument(docId).subscribe({
      next: async () => {
        this.toastService.showSuccess('Document deleted successfully');
        await this.getDocs();
      },
      error: (err) => {
        this.toastService.showError('Error removing document')
      }
    });
  }

  isGreaterThanAllowedSize(file: any){
    return (file.size > constants.documentUploadSizeInMbs * 1000000);
  }

  async getDocs() {
    const res = await this.documentService.getMyCurrentDocuments(this.documentInfo.position.project._id)
    this.documentInfo.position.docs = res;
  }

  updateTodoListItem(){
    if(this.todoListItem){
      this.reviewerService.updateNotificationHistory({id: this.todoListItem._id}, 'complete').subscribe(() => {});
    }
  }
}
