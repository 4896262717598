@if(firm?.isDocusignEnabled){
@if(docuSignEditorOpen){
<edit-template (openDocuSignEditor)="openDocuSignEditor($event)" (updateProject)="updateProject()"
    (updateTemplateDocument)="updateTemplateDocument()" [isEditMode]="isEditMode"
    [projectId]="projectId"></edit-template>
} @else {
<div class="grid grid-nogutters">
    <div class="p-0 mb-4 col-12 flex flex-column xl:flex-row gap-3 xl:gap-6">
        <div class="flex align-items-center">
            @if (selectTemplateType === 'New') {
            <p-button label="BACK" (click)="back()" icon="pi pi-chevron-left" [rounded]="true" size="large" />
            }
        </div>
    </div>
    @if (selectTemplateType === 'New') {
    <div class="p-0 mb-4 col-6">
        <div class="grid grid-nogutter">
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <span class="font-bold white-space-nowrap">
                    Upload Documents
                    Description
                </span>
            </div>

            @if(!selectedDocSignTemplate){
            <div class="col-12 pb-3 pt-0 pr-0 pl-0 mt-3">
                <p-floatLabel>
                    <input id="name" type="text" [(ngModel)]="templateName" [disabled]="selectedDocSignTemplate"
                        required="" pInputText />
                    <label for="name">TemplateName</label>
                </p-floatLabel>
            </div>
            }

            <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                <p class="m-0 text-sm ">Select .pdf within 50 MB:</p>
                <p-button (click)="uploadFile.click()" label="UPLOAD A DOCUMENT" icon="pi pi-plus" size="large"
                    [rounded]="true" />
                <input #uploadFile id="resume" aria-label="resume" type="file" multiple value="..." class="hidden"
                    accept="application/pdf" (change)="uploadDocument($event)">
            </div>
            <div class="mp-uploaded-field" style="width: 60%; align-items: start">
                <ul>
                    @for (fileObject of projectFiles; track fileObject.name) {
                    <li style="text-align: left;">
                        <label class=" mb-0 w-100 " style="display: inline;">{{ fileObject.name }}</label>
                        <i class="icon-file" style="font-size: 18px; display: inline;"></i>
                        <span>
                            <a class="cursor-pointer" ng-click="deleteDocument($index)">
                                <i class="icon-delete"></i>
                            </a>
                        </span>
                    </li>
                    }
                </ul>
            </div>
        </div>
    </div>

    <div class="p-0 mb-4 col-12">
        <div class="data-table">
            @for(document of templateDocuments; track $index){
            <div class="col-6 flex justify-content-between align-items-center px-4 surface-100 border-bottom-1 border-400"
                cdkDrag>
                <span class="text-sm">
                    <p-floatLabel>
                        <input id="username" required="" [(ngModel)]="document.docTitle" type="text" pInputText />
                        <label for="username">Display Name</label>
                    </p-floatLabel>
                </span>
                <div class="actions flex align-items-center">
                    <p-button (click)="viewDocumentForTemplate(document)" [rounded]="true" [text]="true" icon="true"
                        plain="true">
                        <span class="material-symbols-outlined">
                            add
                        </span>
                    </p-button>
                    <p-button (click)="removeDocument($index)" [rounded]="true" [text]="true" icon="true" plain="true">
                        <span class="material-symbols-outlined">
                            delete_forever
                        </span>
                    </p-button>
                </div>
            </div>
            }
        </div>
    </div>
    }
    @if (selectTemplateType === 'Existing') {
    <div
        class="p-0 mb-4 col-12 flex justify-content-start lg:justify-content-between flex-column lg:flex-row align-items-start lg:align-items-center gap-3 lg:gap-0 ">
        <p-iconField iconPosition="right">
            <p-inputIcon styleClass="pi pi-search" />
            <input type="text" pInputText placeholder="Search Keywords" [(ngModel)]="docuSearchText"
                (ngModelChange)="searchDocuTemplate()" />
        </p-iconField>

        <p-button label="NEW TEMPLATE" (click)="addNewTemplate()" icon="pi pi-plus" [rounded]="true" size="large" />
    </div>
    <div class="p-0 mb-4 col-12">
        <div class="data-table">
            @if(this.projectId){
            <p-table #documentTable [value]="documents" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
                [lazy]="true" [paginator]="true" [rows]="2" [totalRecords]="totalDocuSignTemplate"
                [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" [rows]="10"
                (onLazyLoad)="onLazyLoad($event)" [(selection)]="selectedDocuments"
                (onRowCollapse)="onRowCollapse($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                        <th pSortableColumn="name">
                            Template Name
                            <p-sortIcon field="name" />
                        </th>
                        <th style="width: 8%">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" class="border-0" let-document let-expanded="expanded">
                    <tr [pSelectableRow]="document">
                        <td><p-tableCheckbox [value]="document" /></td>
                        <td>
                            {{ document.name }}
                        </td>
                        <td>
                            <div class="wrap-actions">
                                <p-button [rounded]="true" [text]="true" icon="true"
                                    (onClick)="addDocumentInTemplate($event, document)">
                                    <span class="material-symbols-outlined">
                                        add
                                    </span>
                                </p-button>
                                <p-button type="button" pRipple [pRowToggler]="document" [text]="true" [rounded]="true"
                                    [plain]="true" [icon]="
                                                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                                                    " class="row-toggler" />
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-templates>
                    <tr>
                        <td colspan="7" class="p-0">
                            <div class="inner-wrap p-0">
                                <p-table [value]="templates.documents" dataKey="_id">
                                    <ng-template pTemplate="body" let-documents>
                    <tr>
                        <td class="pl-40">{{ documents.name }}</td>
                        <td class="pr-40" style="width: 8%">
                            <div class="wrap-actions flex align-items-center gap-2">
                                <p-button
                                    (click)="editTemplateDocumentView(templates.templateId, documents.documentId, documents.name, templates)"
                                    [rounded]="true" [text]="true" icon="true">
                                    <span class="material-symbols-outlined">
                                        edit
                                    </span>
                                </p-button>
                                <p-button
                                    (click)="viewDocument(templates.templateId, documents.documentId, documents.name)"
                                    [rounded]="true" [text]="true" icon="true">
                                    <span class="material-symbols-outlined">
                                        visibility
                                    </span>
                                </p-button>
                                <p-button [rounded]="true" [text]="true" icon="true"
                                    (onClick)="deleteTemplateDocument($event, documents.documentId, templates.templateId, templates)">
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>
                                </p-button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
        }
    </div>
</div>
<div class="p-0 mb-4 col-12 pl-0 lg:pl-4">
    <p class="m-0 text-black-alpha-90">Documents to be added:</p>
    <ul class="chips m-0 flex-column mt-3">
        @for (doc of selectedDocuments; track $index) {
        <li><p-chip label="{{doc.name}}" /></li>
        }
    </ul>
</div>
}

</div>


@if(selectTemplateType === 'New'){
<div class="col-6 p-0 flex justify-content-end gap-3">
    <p-button [disabled]="isSaveTemplateInProgress" label="Save" (click)="onUploadDocumentsNext()" [rounded]="true"
        size="large" />
</div>
} @else {
<div class="col-12 p-0 flex justify-content-end gap-3">
    <p-button label="Previous" (click)="goToPrevious(0)" [outlined]="true" [rounded]="true" size="large" />
    @if(project?.step !== '7'){
    <p-button label="Save/Finish Later" (click)="saveAndLogout()" [rounded]="true" size="large" />
    <p-button label="Next" (click)="next('next')" [rounded]="true" size="large" />

    } @else {
    <p-button [disabled]="(selectTemplateType === 'New' && !templateDocuments.length) || isSaveTemplateInProgress"
        [label]="'Save ' + (selectTemplateType !== 'New' ? 'And Go To Review' : '')" (click)="next('review')"
        [rounded]="true" size="large" />
    }

</div>
}


<p-dialog [modal]="true" draggable="false" [(visible)]="visibleDocuSignConfirmModal"
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '800px' }">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Is this document required for users who have already been staffed on Project {{project?.codeName}}
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="setDocReqForStaff()" />
        <p-button label="YES" size="large" [rounded]="true" (onClick)="setDocReqForStaff(true)" />
    </ng-template>
</p-dialog>

<p-dialog [modal]="true" draggable="false" [(visible)]="permanentAddconfirmVisible"
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '800px' }">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Do you want to permanently add this document to the template or only for this project?
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="uploadPermanentDocs()" />
        <p-button label="YES" size="large" [rounded]="true" (onClick)="uploadPermanentDocs(true)" />
    </ng-template>
</p-dialog>
<p-dialog [modal]="true" draggable="false" [(visible)]="visible" [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }"
    [style]="{ width: '800px' }">

</p-dialog>

<p-dialog [modal]="true" draggable="false" [(visible)]="visibleMultiDocuSignConfirmModal"
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '800px' }">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Is this document required for users who have already been staffed on Project {{project?.codeName}}
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="setMultiDocReqForStaff()" />
        <p-button label="YES" size="large" [rounded]="true" (onClick)="setMultiDocReqForStaff(true)" />
    </ng-template>
</p-dialog>

<p-dialog [modal]="true" draggable="false" [(visible)]="visible" [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }"
    [style]="{ width: '800px' }">

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Upload Documents
                Description
            </span>
        </div>
    </ng-template>

    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
            <p class="m-0 text-sm ">Select .pdf within 50 MB:</p>
            <p-button (click)="uploadFile.click()" label="UPLOAD A DOCUMENT" icon="pi pi-plus" size="large"
                [rounded]="true" />
            <input #uploadFile id="resume" aria-label="resume" type="file" multiple value="..." class="hidden"
                accept="application/pdf" (change)="uploadDocument($event)">
        </div>
        <div class="mp-uploaded-field" style="width: 60%; align-items: start">
            <ul>
                @for (fileObject of projectFiles; track $index) {
                <li style="text-align: left;">
                    <label class=" mb-0 w-100 " style="display: inline;">{{ fileObject.name }}</label>
                    <i class="icon-file" style="font-size: 18px; display: inline;"></i>
                    <span>
                        <a class="cursor-pointer" ng-click="deleteDocument($index)">
                            <i class="icon-delete"></i>
                        </a>
                    </span>
                </li>
                }
            </ul>
        </div>
        <div class="col-12 pb-3 pt-0 pr-0 pl-0 mt-3">
            <p-floatLabel>
                <input id="username" required="" [(ngModel)]="docTitle" type="text" pInputText />
                <label for="username">Display Name<span class="required">*</span></label>
            </p-floatLabel>
            @if (docTitleRequired) {
            <div class="error-txt-new">This is required.</div>
            }
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button [disabled]="isSaveTemplateInProgress" label="SAVE" size="large" [rounded]="true"
            (onClick)="onUploadDocumentsNext()" />
    </ng-template>
</p-dialog>

}

} @else {
<div class="grid grid-nogutters">
    <div class="p-0 mb-4 col-12 flex flex-column xl:flex-row gap-3 xl:gap-6">
        <p class=" text-black-alpha-90 m-0">What information is needed for {{project?.codeName}}</p>
    </div>

    <div
        class="p-0 mb-4 col-12 flex justify-content-start lg:justify-content-between flex-column lg:flex-row align-items-start lg:align-items-center gap-3 lg:gap-0 ">
        <p-button label="UPLOAD A DOCUMENT" (click)="showResumeDialog()" icon="pi pi-plus" [rounded]="true"
            size="large" />
    </div>
</div>

<p-table [value]="reviewDocuments" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)" [(selection)]="selectedDocuments"
    (onRowCollapse)="onRowCollapse($event)">
    <ng-template pTemplate="header">
        <tr>
            <th>
                Title
            </th>
            <th>
                Document Name
            </th>
            <th style="width: 8%">Action</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" class="border-0" let-documents let-expanded="expanded">
        <tr>
            <td>
                {{ documents.docTitle }}
            </td>
            <td>
                {{ documents.title }}
            </td>
            <td class="pr-40" style="width: 8%">
                <div class="wrap-actions flex align-items-center gap-2">
                    <p-button [rounded]="true" [text]="true" icon="true" (click)="showEditResumeDialog(documents)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                    <p-button [rounded]="true" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            delete
                        </span>
                    </p-button>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
<div class="col-12 p-0 flex justify-content-end gap-3 mt-3">
    <p-button label="Previous" (click)="goToPrevious(0)" [outlined]="true" [rounded]="true" size="large" />
    @if(project?.step !== '7'){
    <p-button label="Save/Finish Later" (click)="saveAndLogout()" [rounded]="true" size="large" />
    <p-button label="Next" (click)="next('next')" [rounded]="true" size="large" />

    } @else {
    <p-button label="Save And Go To Review" (click)="next('review')" [rounded]="true" size="large" />
    }
</div>

<form [formGroup]="resumeDocumentForm">
    <p-dialog [modal]="true" draggable="false" [(visible)]="visibleResumeDialog"
        [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" [style]="{ width: '660px' }">

        <ng-template pTemplate="header">
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <span class="font-bold white-space-nowrap">
                    Upload File
                </span>
            </div>
        </ng-template>

        @if(!isEditResumeDocument){
        <div class="grid grid-nogutter">
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                <p-button (click)="uploadResume.click()" [label]="fileName" icon="pi pi-plus" size="large"
                    [rounded]="true" />
                <input #uploadResume id="resume" type="file" class="hidden" value="..." name="file" accept="image/*,text/plain,application/pdf,application/msword,
                                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                        application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                                        ,.doc,.docx,.xls,.xlsx,.txt" button-disable
                    (change)="uploadResumeDocument($event)">
            </div>
        </div>
        }
        <div class="grid grid-nogutter">
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                <p-floatLabel>
                    <input pInputText id="title" formControlName="docTitle" placeholder="Place your title here" />
                    <label for="title">Title<span class="required">*</span></label>
                </p-floatLabel>
                @if(resumeForm['docTitle'].dirty || resumeForm['docTitle'].touched){
                @if(resumeForm['docTitle'].errors?.['required']){
                <div class="p-error ">This is required.</div>
                }
                }
            </div>
        </div>

        <div class="grid grid-nogutter">
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                <p-floatLabel>
                    <textarea rows="5" cols="30" pInputTextarea formControlName="instructions"
                        placeholder="Place your instructions here">
                        </textarea>
                    <label for="username">Instructions for Freelancers.</label>
                </p-floatLabel>

            </div>
        </div>

        <ng-template pTemplate="footer">
            @if(isEditResumeDocument){
            <p-button [disabled]="isSaveTemplateInProgress" type="submit" label="SAVE" size="large" [rounded]="true"
                (onClick)="updateResumeDocumentAndNext()" />
            } @else {
            <p-button [disabled]="isSaveTemplateInProgress" type="submit" label="SAVE" size="large" [rounded]="true"
                (onClick)="saveResumeDocumentAndNext()" />
            }
        </ng-template>
    </p-dialog>
</form>
}